import {
  useSocket,
  socketURL,
  socketDeserializers,
} from "./alertycommon/lectureStream";
import {
  Question,
} from "./alertycommon/types";
import {
  useState,
  useEffect,
  useCallback,
  SetStateAction,
} from 'react';
export const useSendQuestions = (
  questions: Question[],
  setQuestions: (questions: SetStateAction<Question[]>) => void,
  shouldSendQuestions: boolean = true,
  lectureId: string = "",
  url: string = `${socketURL}studentQuestions/`,
  alreadySentQuestions: Question[] = [],
) => {
  interface SentQuestions {
    [key: string]: true | undefined; 
  }
  const [sentQuestions, setSentQuestions] = useState<SentQuestions>(
    alreadySentQuestions.reduce((sent: SentQuestions, q: Question) => ({
      ...sent,
      [q.id]: true as const,
    }), {})
  );
  const answerQuestion = useCallback(([id, answer]: ReturnType<(typeof socketDeserializers)['alerty_student_questions']>) => {
    setQuestions(questions => questions.map(q => q.id === id ? { ...q, answer } : q));
  }, [setQuestions]);
  const [questionSocketValid, askQuestion] = useSocket(url, 'alerty_student_questions', answerQuestion);
  useEffect(() => {
    if (!shouldSendQuestions) return;
    if (!questionSocketValid) return;
    let newSentQuestions : SentQuestions = {};
    let changed = false;
    for (let q of questions) {
      if (!sentQuestions[q.id]) {
	changed = true;
	newSentQuestions[q.id] = true;
	console.log("Sending question:");
	console.log(q);
	askQuestion(JSON.stringify({
	  ...q,
	  alertyLectureId: lectureId,
	}));
      }
    }
    if (changed) setSentQuestions(oldSentQuestions => ({...oldSentQuestions, ...newSentQuestions}));
  }, [
    questionSocketValid,
    askQuestion,
    questions,
    sentQuestions,
    setSentQuestions,
    shouldSendQuestions,
    lectureId,
  ]);
}
