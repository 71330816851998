export default `[Now, I'm looking out at all of these faces ]
[and I'm assuming that many of you ]
[have probably arrived here with some preconceived notions ]
[about the American Revolution. ]
[I'm assuming that at least some of you ]
[are sitting there and in the back of your mind ]
[you're thinking|]
[Declaration of Independence, ]
[a bunch of battles, ]
[George Washington, ]
[a little bit of Paul Revere thrown in|]
[and all of those things ]
[are going to appear in the course ]
[but obviously {the real American Revolution ]
[is a lot more complex than that}. ]
[It's more than a string of ]
[names and documents and battles, ]
[and as a matter of fact ]
[{in many ways the American Revolution ]
[wasn't just a war.}]
[|If you went back ]
[to the mid-eighteenth century, ]
[went back to the period ]
[of the Revolution or ]
[maybe just after it, ]
[and you asked people ]
[how they understood what was happening, ]
[{many of them would tell you ]
[that the war was actually ]
[only a minor part ]
[of the American Revolution}.|]
[Some would tell you ]
[the war actually wasn't ]
[the American Revolution at all]
[ and you'll see the|]
[{I should mention that ]
[the syllabus is finally up online} ]
[so it's there for you, ]
[but you will see ]
[when you look at ]
[the syllabus that ]
[at the very start of it ]
[there are two quotes ]
[and I want to read them here because they make this point really well.|]
[So the first quote ]
[is from a letter by John Adams ]
[and he's writing to Thomas Jefferson ]
[in 1815 and ]
[he's heard about an attempt ]
[to write the history of the American Revolution ]
[so {this is what Adams ]
[has to say about that.}]
[|As to the history of the Revolution, ]
[my ideas may be peculiar, ]
[perhaps singular, ]
[but what do we mean ]
[by the Revolution? ]
[{The war? ]
[That was no part of the Revolution.}|]
[There is the moment where you go]
[ Huh?|]
[It was only an effect ]
[and consequence of it. ]
[{The Revolution was in the minds of the people, ]
[and this was effected ]
[from 1760 to 1775, ]
[in the course of fifteen years} ]
[before a drop of blood was drawn at Lexington.|]
[Okay. ]
[So there we have John Adams ]
[saying that the war was actually ]
[no part of the Revolution. ]
[It's a pretty famous quote ]
[but it's a pretty interesting statement.|]
[Now I want to mention here, ]
[and it's very early in the course ]
[for me to have worked you ]
[into liking John Adams ]
[and I'm going to talk more ]
[about John Adams in a few minutes, ]
[but I will mention here ]
[since I've just read that quote ]
[if partway through the semester ]
[you decide you're just dying ]
[to read dead people's mail, ]
[which is basically what historians do for a living, ]
[a great volume to read ]
[is actually the letters that ]
[Jefferson and Adams sent ]
[back and forth to each other ]
[over the course of their lives. ]
[They've all been pulled together ]
[into one volume and the best part ]
[of that volume is the end section, ]
[the letters in which these guys ]
[were writing to each other ]
[in their old age. ]
[So you have these two Founder Figures, ]
[former presidents, ]
[and they're just basically letting it rip ]
[in these letters. ]
[They're talking about everything. ]
[They're talking about all the things ]
[actually you probably wouldn't talk about normally|] 
[religion, ]
[politics, ]
[who they hate, ]
[who they like, ]
[what they thought of the Revolution, ]
[what they thought of their own presidency, ]
[what they thought ]
[of the other guy's presidency, ]
[the top ten Founder funerals.|]
[Actually there's a little section, ]
[although I think it's ]
[the top three Founder funerals, ]
[but it's a weird, really interesting ]
[range of stuff and it's just ]
[these two people really excited ]
[about the fact that they've retired ]
[and all they need to do now ]
[is write to each other and ]
[really get to know each other better. ]
[So it's a great volume. ]
[{It's edited by Lester Cappon. ]
[The last name is C-a-p-p-o-n if you're interested.|}]
`;
