export interface LiveLecture {
  saved: false;
  id: string;
  className: string;
  teacherName?: string;
  socketURL: string;
}
export interface SavedLecture {
  saved: true;
  id: string;
  className: string;
  teacherName?: string;
  startDate?: Date;
  customName?: string;
}
export type Lecture = LiveLecture | SavedLecture;

// eslint-disable-next-line
import {
  lecturesURL,
  socketURLForID,
} from "./multilectureConstants";

interface LecturesResponse {
  items: Array<{
    metadata: {
      name: string;
    };
    spec: {
      className: string;
      teacherName: string;
    };
  }>;
}
export const sampleLectures: Lecture[] = [
  {
    saved: true,
    id: "SAMPLE_LECTURE",
    className: "Data Science",
  },
  /*
  {
    saved: true,
    id: 'SAMPLE_EVOBIO',
    className: "Evolutionary Biology",
  },
  {
    saved: true,
    id: 'SAMPLE_PHYSICS',
    className: "Physics",
  },
  */
  {
    saved: true,
    id: 'SAMPLE_HISTORY',
    className: "US History",
  },
  /*
  {
    saved: true,
    id: 'SAMPLE_LIT',
    className: "US Literature",
  },
  {
    saved: true,
    id: 'SAMPLE_OCHEM',
    className: "Organic Chemistry",
  },
  */
];

/**
 * Fetches the list of all lectures visible to the current user.
 */
export const fetchLectures = async (
  /**
   * The user's login token.
   */
  loginToken: string,
  fallbackToSamples = true,
): Promise<Lecture[]> => {
  try {
    console.log("Fetching...");
    const fetched = await Promise.race([
      fetch(lecturesURL, {
	headers: {
	  "Authorization": `Bearer ${loginToken}`,
	},
      }),
      new Promise<never>((resolve, reject) => {
	const wait = setTimeout(() => {
	  clearTimeout(wait);
	  reject("Lecture fetch timed out");
	}, 1500);
      }),
    ]);
    console.log("Fetched, getting JSON...");
    const response = await fetched.json() as LecturesResponse;
    console.log("Got JSON, mapping...")
    const liveLectures : LiveLecture[] = response.items.map(item => ({
      saved: false,
      id: item.metadata.name,
      className: item.spec.className,
      teacherName: item.spec.teacherName,
      socketURL: socketURLForID(item.metadata.name)
    }));
    return liveLectures;
  }
  catch (e) {
    if (fallbackToSamples) {
      console.log("Failed to fetch lectures; returning samples");
      console.log(e);
      return sampleLectures;
    }
    else {
      throw e;
    }
  }
};
