export const tryAccess = <Key extends string | number | symbol>(
  obj: unknown,
  /** compile-time safety check: it must be statically known that field doesn't start
   * with '__', meaning it can't be '__prototype__' or similar, and also can't be
   * unchecked user input
   */
  field: Key & (
    (`__${string}` & Key) extends never ? Key
    : 'error: field must be known not to start with __'
  ),
  default_: unknown = undefined,
): unknown => {
  if (obj instanceof Object && (field as Key) in obj) return (obj as any)[field];
  return default_;
}
export type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
}
/**
 * Param check for any string.
 */
export const anyString = (str: string): str is string => true;
/**
 * Param check for a string within an array of enum values.
 * Error message shows the list of allowed values.
 */
export const inArray =
  <TArray extends readonly [string, ...string[]] | readonly string[]>(array: TArray) =>
  (str: string, key: string): str is TArray[number] => {
  if (array.some(v => v === str)) return true;
  throw new Response(`Invalid value "${str}" for parameter ${key}
(expected one of [${array.map(v => `"${v}"`).join(", ")}])`, {status: 400});
};
/**
 * Specify required params and their legal values (as subsets of string).
 */
export type ParamChecks<Params> = {
  /**
   * Should return true for valid values.
   * For invalid values, throw a custom error message or return false for a default one.
   */
  [K in keyof Params]: (str: string, key: string) => str is Params[K] & string;
}
export const typedLoader = <
  Key extends string,
>(
  loader: ((args: {
    request: Request,
    params: Params<Key>,
  }) => any)
): ((args: {
    request: Request,
    params: Params<string>,
  }) => any) => {
    return loader;
};
export const checkedLoader = <
  Key extends string,
  CheckedKey extends Key,
  CheckedParams extends Params<CheckedKey>,
>(
  checks: ParamChecks<CheckedParams>,
  loader: ((args: {
    request: Request,
    params: Params<Key> & CheckedParams,
  }) => any),
): ((args: {
  request: Request,
  params: Params<string>,
}) => any) => {
  return ({ request, params }) => {
    for (let [key, check] of Object.entries(checks)) {
      const val = params[key];
      if (val === undefined) {
	throw new Response(`Missing parameter ${key}`, { status: 400 });
      }
      if (!(check as (str: string) => boolean)(val)) {
	throw new Response(`Invalid value "${val}" for parameter ${key}`, { status: 400 });
      }
    }
    return loader({
      request,
      params: params as Params<Key> & CheckedParams,
    });
  };
};
export const error = (...args: ConstructorParameters<typeof Error>): never => {
  throw new Error(...args);
}
