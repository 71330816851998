import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback,
  useMemo,
  createContext,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  Map,
} from 'immutable';
import {
  useResizeDetector,
} from 'react-resize-detector';

interface SidebarContext {
  updateBubble(update: [
    type: 'remove',
    id: string,
  ] | [
    id: string,
    target: number,
    height: number,
    sortTiebreaker?: number,
  ]): void;
  actualPosition(id: string): number;
}
const SidebarContext = createContext<SidebarContext | undefined>(undefined);

export type SidebarProps = React.PropsWithChildren<{
}>;
const Sidebar = ({
  children,
}: SidebarProps) => {
  type Bubble = [
    target: number,
    height: number,
    sortTiebreaker?: number,
  ];
  const [bubbles, updateBubble] = useReducer((
    bubbles: Map<string, Bubble>,
    update: Parameters<SidebarContext['updateBubble']>[0],
  ) => {
    if (update.length === 2 && update[0] === 'remove') {
      return bubbles.remove(update[1]);
    }
    else {
      const [id, target, height, sortTiebreaker] = update;
      return bubbles.set(id, [target, height, sortTiebreaker]);
    }
  }, Map<string, Bubble>());
  const actualPositions = useMemo(() => {
    type BubbleData = [id: string, data: [target: number, height: number, sortTiebreaker?: number]];
    const bubbleData = [...bubbles.entries()]
      .sort((
        [idA, [targetA, heightA, tiebreakerA]],
        [idB, [targetB, heightB, tiebreakerB]]
      ) => (
        (targetA - targetB)
        // 0 is falsy, positive and negative are truthy
        || ((tiebreakerA ?? 0) - (tiebreakerB ?? 0)))
      );
    const collides = (a: BubbleData, b: BubbleData) => {
      const [_, [targetA, heightA]] = a;
      const [__, [targetB, heightB]] = b;
      return Math.abs(targetA - targetB) < (heightA / 2) + (heightB / 2);
    };
    const positionedBatches: [bubbles: BubbleData[], collision: BubbleData][] = [];
    let currentBatch: BubbleData[] = [];
    let batchCollision: BubbleData | undefined = undefined;

    const computeBatchTarget = (batch: BubbleData[], batchHeight: number) => {
      if (batch.length === 0) return 0;
      if (batch.length === 1) return Math.max(batch[0][1][0], 60 + batch[0][1][1] / 2);
      // a batch forms when the space between 2 bubbles is less than the height between
      // their centers
      // the correct height is somewhere between the targets of the top and bottom bubbles
      // for an initial guess, take the point halfway between their targets
      const [firstTarget, firstHeight] = batch[0][1];
      const [lastTarget, lastHeight] = batch[batch.length - 1][1];
      const baseCenter = (firstTarget / 2) + (lastTarget / 2);

      // calculate how far each bubble is from its target, including which direction it's
      // wrong in
      //const wrongnesses: number[] = [];
      let top = baseCenter - (batchHeight / 2);
      let totalWrongness = 0;
      for (const bubble of batch) {
        const [id, [target, height]] = bubble;
        const wrongness = (top + (height / 2)) - target;
        //wrongnesses.push(wrongness);
        totalWrongness += wrongness;
        console.log(`wrongness for ${id} is ${wrongness}`);
        top += height;
      }
      // we used to take the median here, but Jade thinks average feels better
      return Math.max(
        batchHeight / 2,
        60 + baseCenter - (totalWrongness / batch.length),
      );

      // median algorithm preserved below in case it's wanted later
      //wrongnesses.sort((a, b) => (a - b));

      /*
      // assume each bubble pushes/pulls the group towards its target with equal strength
      // if we take the median wrongness and bring it to 0, there are just as many bubbles
      // opposed to upward movement as downward movement, so we're at the correct position

      // let's find that median!
      if (wrongnesses.length % 2 === 0) {
        const center = (wrongnesses.length / 2) - 1;
        // mean of the center 2 wrongnesses
        const medianWrongness = (wrongnesses[center] / 2) + (wrongnesses[center + 1] / 2);
        console.log(`medianWrongness is: ${medianWrongness}`);
        // don't allow pushing bubbles above the top of the page
        return Math.max(batchHeight / 2, baseCenter - medianWrongness);
      }
      else {
        // the center wrongness
        const medianWrongness = wrongnesses[(wrongnesses.length - 1) / 2];
        console.log(`medianWrongness is: ${medianWrongness}`);
        return Math.max(batchHeight / 2, baseCenter - medianWrongness);
      }
       */
    };
    // TODO PERF
    // This is quadratic, but maybe it doesn't have to be?
    for (const bubble of bubbleData) {
      const [id, [target, height]] = bubble;

      // if this bubble hasn't been rendered yet, or isn't visible, don't collide it
      if (target === Number.POSITIVE_INFINITY || height === 0) {
        console.log(`Skipping ${id}; target = ${target}, height = ${height}`);
        continue;
      }

      if (batchCollision !== undefined && collides(bubble, batchCollision)) {
        console.log(`Adding ${id} to batch [${currentBatch.map(b => b[0]).join(", ")}]`);
        // put this bubble into the current batch
        currentBatch.push(bubble);
        // update collision
        let batchHeight: number = batchCollision![1][1];
        batchCollision = ["batch", [
          computeBatchTarget(currentBatch, batchHeight + height),
          batchHeight + height,
        ]];
        // check collision between current batch and previous batches
        while (
          positionedBatches.length > 0
          && collides(batchCollision!, positionedBatches[positionedBatches.length - 1][1])
        ) {
          // merge current batch with previous batch
          const [previousBatch, previousCollision] = positionedBatches.pop()!;
          currentBatch = previousBatch.concat(currentBatch);
          batchHeight = batchCollision![1][1];
          batchCollision = ["batch", [
            computeBatchTarget(currentBatch, batchHeight + previousCollision[1][1]),
            batchHeight + previousCollision[1][1],
          ]];
        }
      }
      else {
        // finish previous batch, start new one with just this bubble
        if (batchCollision) {
          positionedBatches.push([
            currentBatch,
            batchCollision,
          ]);
        }
        currentBatch = [bubble];
        batchCollision = ["batch", [
          // make sure we don't start above the top of the page
          computeBatchTarget(currentBatch, height),
          height,
        ]];
      }
    }
    if (batchCollision) {
      positionedBatches.push([
        currentBatch,
        batchCollision,
      ]);
    }
    const positionedBubbles = positionedBatches.flatMap(([bubbles, [_, [center, height]]]) => {
      let top = center - (height / 2);
      const positionedBubbles: [id: string, top: number][] = [];
      for (const bubble of bubbles) {
        const [id, [__, bubbleHeight]] = bubble;
        console.log(`Placing ${id}; top = ${top}`);
        positionedBubbles.push([id, top]);
        top += bubbleHeight;
      }
      return positionedBubbles;
    }).concat(bubbleData // not-yet-rendered bubbles get their targets
      .filter(([id, [target, height]]) => (target === Number.POSITIVE_INFINITY) || (height === 0))
      .map(([id, [target, height]]) => {
        console.log(`Placing ${id} (first render or invisible): target = ${target}, height = ${height}`);
        return [id, target];
      })
    );
    return Map(positionedBubbles);
  }, [bubbles]);
  const actualPosition = useCallback((id: string) => (
    actualPositions.get(id, Number.NEGATIVE_INFINITY)
  ), [
    actualPositions,
  ]);
  const context: SidebarContext = useMemo(() => ({
    updateBubble,
    actualPosition,
  }), [
    updateBubble,
    actualPosition,
  ]);
  return (<SidebarContext.Provider
    value={context}
    children={children}
  />);
};
export default Sidebar;
export interface SidebarBubbleProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  target: number;
  sortTiebreaker?: number;
}
export const errorNoSidebar = () => { throw new Error('SidebarBubble: must be a child of Sidebar'); };
export const SidebarBubble = React.forwardRef<HTMLDivElement, SidebarBubbleProps>(({
  id,
  target,
  sortTiebreaker,
  style,
  children,
  ...props
}: React.PropsWithChildren<SidebarBubbleProps>, outerRef) => {
  const [height, setHeight] = useState(0);

  // we need to handle 3 values for outerRef:
  // - null: easy!
  // - ref object: we can just use that object for all our ref purposes
  // - function: a little tricky...
  const ourRef = useRef<HTMLDivElement>(null);
  const targetRef = (outerRef === null || typeof outerRef === 'function')
    ? ourRef // whatever they gave us, it's not a ref object
    : outerRef; // it is a ref object, just use it

  // if they gave us a function, we need to use our own function to make sure theirs
  // gets called at the right times
  const [refValue, setRefValue] = useState<HTMLDivElement | null>(null);
  const refFunction = useCallback((element: HTMLDivElement | null) => {
    if (typeof outerRef === 'function') {
      setRefValue(element);
      outerRef(element);
    }
    // this should never get called otherwise, so nothing to do!
  }, [
    outerRef,
    setRefValue,
  ]);
  // if they gave us a function, useResizeObserver still wants a ref object, so we need
  // to make sure it gets one that's being updated
  // to do this, we need a slightly unorthodox use of useImperativeHandle modifying
  // the ref object *we created* to keep up with the values passed to our ref function
  // if they didn't, though, we still have to follow the hook rules, so we need a
  // sacrificial ref that'll never get updated
  const placeholderRef = useRef<HTMLDivElement>(null);
  useImperativeHandle(
    typeof outerRef === 'function' ? ourRef : placeholderRef,
    // for some reason, React's types say this shouldn't ever be null. That seems wrong
    // since refs are null all the time.
    () => refValue!, 
    [
      refValue,
    ],
  );

  const updateHeight = useCallback((width?: number, height?: number) => {
    console.log(`updateHeight for ${id}: ${targetRef.current} ${(targetRef.current?.offsetHeight ?? 0) + 4}`);
    setHeight((targetRef.current?.offsetHeight ?? 0) + 4);
  }, [
    setHeight,
    targetRef,
    id,
  ]);
  const {
    ref: resizeDetectorRef,
  } = useResizeDetector({
    targetRef,
    handleWidth: false,
    onResize: updateHeight,
  });
  useEffect(() => {
    setTimeout(() => updateHeight, 1);
  }, [
    updateHeight,
    targetRef.current,
  ]);

  const ref = typeof outerRef === 'function' ? refFunction : resizeDetectorRef;
  const {
    updateBubble,
    actualPosition,
  } = useContext(SidebarContext) || {
    updateBubble: errorNoSidebar,
    actualPosition: errorNoSidebar,
  };

  // only remove when id changes 
  useEffect(() => () => {
    updateBubble(['remove', id])
  }, [
    updateBubble,
    id,
  ]);
  // update whenever target or height changes
  useEffect(() => {
    updateBubble([
      id,
      target,
      height,
      sortTiebreaker,
    ]);
  }, [
    id,
    target,
    height,
    sortTiebreaker,
    updateBubble,
  ]);
  const top = actualPosition(id);
  const style_: React.CSSProperties = useMemo(() => ({
    ...(style ?? {}),
    top: `${top}px`,
  }), [
    style,
    top,
  ]);

  return (<aside
    className={'sidebarBubble'}
    ref={ref}
    style={style_}
    children={children}
    {...props}
  />);
});

export const useSidebarPosition = (
  id: string,
  target: number,
): number => {
  return 0;
};
