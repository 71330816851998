import React, {
  useState,
  useCallback,
} from 'react';
import {
  compressToEncodedURIComponent,
} from 'lz-string';
const MakeLectureShareLink = () => {
  const [url, setURL] = useState("");
  const [output, setOutput] = useState<string>();
  const handleChange = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setURL(event.currentTarget.value);
  }, [
    setURL,
  ]);
  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOutput(`https://app.alerty.live/app/import/${compressToEncodedURIComponent(url)}/`);
  }, [
    setOutput,
    url,
  ]);
  return (<>
    <form onSubmit={handleSubmit}>
      <label>
        Enter a URL to a .ribbit file (hosted on another site):
        <br />
        <input
          type='text'
          onChange={handleChange}
          value={url}
        />
      </label>
      <br />
      <input
        type='submit'
        value='Generate Link'
      />
    </form>
    {output ? (<>
      Your shareable URL is:
      <br />
      <a href={output}>
        {output}
      </a>
    </>) : <></>}
  </>);
}
export default MakeLectureShareLink;
