import {
  Message,
  Question,
} from "./types";
import { useState, useReducer, useEffect, useCallback, useRef } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { useStateIfMounted } from 'use-state-if-mounted';
/*
import { polyfill } from 'react-native-polyfill-globals/src/fetch';
polyfill();
*/

const restart = (condition: () => boolean, callback: () => void) => () => condition() && callback();
export const socketURL = "http://demo.alerty.live/sockets/";
export const socketDeserializers = {
  alerty_transcript: (data: string) => JSON.parse(data) as Message | Message[],
  alerty_vibration: (data: string) => undefined,
  alerty_student_questions: (data: string) => JSON.parse(data) as [string, Required<Question>['answer']],
  alerty_teacher_questions: (data: string) => JSON.parse(data) as Question,
} as const;
export const socketRequiresReadyMessage = {
  alerty_transcript: true,
  alerty_vibration: false,
  alerty_student_questions: false,
  alerty_teacher_questions: false,
} as const;
type SocketProtocol = keyof typeof socketDeserializers;
export type SocketMessage<TProtocol extends SocketProtocol> = ReturnType<(typeof socketDeserializers)[TProtocol]>;
const makeSocket = (url: string, protocol?: keyof typeof socketDeserializers) => new Promise<WebSocket>((resolve, reject) => {
  console.log(`makeSocket: ${url}, ${protocol}`);
  const socket = new WebSocket(url, protocol);
  socket.onopen = () => {
    console.log(`socket ${url}, ${protocol} open`);
    resolve(socket);
  };
});

export const useSocket = <TProtocol extends SocketProtocol>(
  url: string,
  protocol: TProtocol,
  messageCallback: (message: SocketMessage<TProtocol>) => void,
  open = true,
  reopenOnURLChange = true,
  readyMessageContent = 'ready',
) => {
  const [currentSocket, setCurrentSocket] = useStateIfMounted<WebSocket | undefined>(undefined);
  const [socketValid, setSocketValid] = useStateIfMounted(false);

  const urlRef = useRef(url);
  useEffect(() => void (urlRef.current = url), [url]);
  const readyRef = useRef(readyMessageContent);
  useEffect(() => void (readyRef.current = readyMessageContent), [url]);

  useAsyncEffect(async isMounted => {
    if (!open) return;
    if (socketValid) return currentSocket;
    const socket = await makeSocket(urlRef.current, protocol);
    socket.onclose = () => {
      setSocketValid(false);
    }
    socket.onerror = () => {
      setSocketValid(false);
    }
    setCurrentSocket(socket);
    setSocketValid(socket.readyState === 1);
    return socket;
  }, socket => {
    if (!socketValid && socket?.readyState === 1) return; // don't close the socket we just opened
    socket?.close(1000, "reopening");
  }, [protocol, socketValid, reopenOnURLChange || url, open]);

  useEffect(() => {
    if (!socketValid || currentSocket === undefined) return () => {};
    currentSocket.onmessage = event => messageCallback(socketDeserializers[protocol](event.data) as SocketMessage<TProtocol>);
    return () => currentSocket.onmessage = () => {};
  }, [socketValid, currentSocket, protocol, messageCallback]);

  useEffect(() => {
    if (!socketRequiresReadyMessage[protocol]) return () => {};
    if (!socketValid || currentSocket === undefined) return () => {};
    if (currentSocket.readyState === 1) {
      currentSocket.send(readyMessageContent);
      return () => {};
    }
    else {
      currentSocket.onopen = () => currentSocket.send(readyMessageContent);
      return () => currentSocket.onopen = () => {};
    }
  }, [socketValid, currentSocket, socketRequiresReadyMessage[protocol]]);

  return [socketValid, useCallback((...args: Parameters<WebSocket['send']>) =>
    socketValid && (currentSocket !== undefined) && currentSocket.send(...args),
  [socketValid, currentSocket])] as const;
};

export const useLectureSubscription = (
  messageCallback: (m: Message) => void,
  lectureId: string,
  url: string = `${socketURL}transcript/`,
  lastSeenMessageNumber: number | false = 0,
  open = true,
): void => {
  const [newestMessageNumber, setNewestMessageNumber] = useState(lastSeenMessageNumber);
  const ourMessageCallbackSingle = useCallback((m: Message) => {
    setNewestMessageNumber(old => old < m.msgNumber ? m.msgNumber : old);
    messageCallback(m);
  }, [messageCallback]);
  const ourMessageCallback = useCallback((m: Message | Message[]) => {
    if (Array.isArray(m)) {
      m.map(ourMessageCallbackSingle);
    }
    else {
      ourMessageCallbackSingle(m);
    }
  }, [ourMessageCallbackSingle]);
  useSocket(
    url,
    'alerty_transcript',
    ourMessageCallback,
    open,
    false,
    JSON.stringify({
      alertyLectureId: lectureId,
      protocolVersion: "1.1.0",
      lastMessage: newestMessageNumber,
    }),
  );
}

export const useVibrationSubscription = (
  vibrateCallback: () => void,
  url: string,
  open = true,
): void => {
  useSocket(
    url,
    'alerty_vibration',
    vibrateCallback,
    open,
  );
}
