import { useRouteError } from "react-router-dom";
import {
  tryAccess,
} from "./utils";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);
  //TODO if deep-linked straight to an error, somehow set HTTP status

  return (
    <div id="error-page">
      <h1>Error {tryAccess(error, 'status') as number ?? ""}</h1>
      <p className='humor'>Sorry, looks like the site croaked...</p>
      <p>{
          tryAccess(error, 'statusText') as string
          || tryAccess(error, 'message') as string
          || tryAccess(error, 'data') as string
          || "Unknown error"
      }</p>
    </div>
  );
}
