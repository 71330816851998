import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  SidebarBubble,
} from "./Sidebar";
export interface NoteData {
  id: string;
  initialText?: string;
  focusOnMount?: true;
  /**
   * Incrementing this will cause this note's input to become focused.
   */
  refocusCount?: number;
}
export interface NoteProps extends NoteData {
  /**
   * The elements this is a note about. The note bubble will be placed so its center is
   * at the same height as the center of these elements.
   */
  attachmentElements?: HTMLElement[];
  /**
   * Used as a SidebarBubble sortTiebreaker. Should be the start time of the content
   * this is a note about.
   */
  startTime?: number;
  deleteNote: (id: string) => void;
  noteContent: Map<string, string>;
}
const Note = ({
  id,
  initialText,
  focusOnMount,
  attachmentElements,
  refocusCount,
  deleteNote,
  startTime,
  noteContent,
}: NoteProps) => {
  const attachmentHeight = useMemo(() => {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    for (const element of attachmentElements ?? []) {
      const top = element.getBoundingClientRect().top + document.documentElement.scrollTop;
      const bottom = element.getBoundingClientRect().bottom + document.documentElement.scrollTop;
      if (top < min) min = top;
      if (bottom > max) max = bottom;
    }
    if (min === Number.POSITIVE_INFINITY || max === Number.NEGATIVE_INFINITY) {
      return undefined;
    }
    return (min / 2) + (max / 2);
  }, [
    attachmentElements,
    attachmentElements?.map(element => element.getBoundingClientRect().toJSON()),
  ]);

  const [content, setContent] = useState(initialText ?? "");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  //TODO save content to LocalStorage (on change? on unmount?)
  const handleChange = useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
    setContent(event.currentTarget.value);
    noteContent.set(id, event.currentTarget.value);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [
    setContent,
    textAreaRef,
    noteContent,
    id,
  ]);
  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }, []);
  useEffect(() => {
    if (refocusCount !== undefined) {
      textAreaRef.current?.focus();
    }
  }, [textAreaRef, refocusCount]);
  const [focusedOnMount, setFocusedOnMount] = useState(false);
  useEffect(() => {
    if (focusOnMount && !focusedOnMount) {
      if (attachmentHeight !== undefined) {
        setFocusedOnMount(true);
      }
      textAreaRef.current?.focus();
    }
  }, [textAreaRef, focusOnMount, attachmentHeight]);
  return (<SidebarBubble
    className='note'
    id={id}
    target={attachmentHeight ?? Number.POSITIVE_INFINITY}
    sortTiebreaker={startTime}
  >
    <form onSubmit={handleSubmit}>
      <textarea
        onChange={handleChange}
        value={content}
        ref={textAreaRef}
        rows={content?.length ? undefined : 1}
      />
    </form>
      <button onClick={() => deleteNote(id)}>
        {'\u2715'}
      </button>
  </SidebarBubble>);
};
export default Note;
