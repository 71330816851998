import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';
import {
  GoogleLogin,
  GoogleOAuthProvider,
  CredentialResponse,
  useGoogleOneTapLogin,
  PromptMomentNotification,
  googleLogout,
} from '@react-oauth/google';
import {
  Navigate,
  Outlet,
} from 'react-router';
import * as jose from 'jose';
import {
  importJWK,
  jwtVerify,
} from 'jose';
import {
  get,
  set,
} from 'idb-keyval';
import {
  Helmet,
} from 'react-helmet';

export interface LoginTokenContext {
  loginToken: string | undefined;
}
export const LoginTokenContext = createContext<LoginTokenContext>({
  loginToken: undefined,
});

interface SetLoginTokenContextNotExpiring {
  setLoginToken(token: string | undefined): void;
  expiringSoon: false;
  expirationDate?: Date;
}
interface SetLoginTokenContextExpiring {
  setLoginToken(token: string | undefined): void;
  expiringSoon: true;
  expirationDate: Date;
}
type SetLoginTokenContext = 
  SetLoginTokenContextExpiring
  | SetLoginTokenContextNotExpiring;

const SetLoginTokenContext = createContext<SetLoginTokenContext>({
  setLoginToken: () => {},
  expiringSoon: false,
});

export interface LoginProviderProps {
}

const JWKS = jose.createRemoteJWKSet(new URL('https://www.googleapis.com/oauth2/v3/certs'));

export const LoginProvider = ({
  children,
}: PropsWithChildren<LoginPageProps>) => {
  const [loginToken, setLoginToken] = useState<string>();
  const [expiringSoon, setExpiringSoon] = useState(false);
  const [expirationDate, setExpirationDate] = useState<Date>();
  const renewTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const expireTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const updateLoginToken = useCallback(async (token: string | undefined) => {
    if (token === undefined) {
      clearTimeout(renewTimeoutRef.current);
      googleLogout();
      setLoginToken(undefined);
      set('savedLoginToken', undefined);
      return;
    }
    if (token === 'expired') {
      // sentinel value - saved to db when token expires and isn't renewed
      setExpiringSoon(true);
      setExpirationDate(new Date());
    }
    try {
      const { payload, protectedHeader } = await jwtVerify(
	token,
	JWKS,
      );
      const expirationDate = (
	payload.exp === undefined ? undefined
	: new Date(payload.exp * 1000) // exp is in seconds since Unix epoch
      );
      if (renewTimeoutRef !== undefined) {
	clearTimeout(renewTimeoutRef.current);
      }
      if (expireTimeoutRef !== undefined) {
	clearTimeout(expireTimeoutRef.current);
      }
      if (expirationDate !== undefined) {
	setExpirationDate(expirationDate);
	if (expirationDate.valueOf() > (Date.now() + (5 * 60 * 1000))) {
	  setExpiringSoon(false);
	}
	console.log(`Token expires at ${expirationDate.toLocaleTimeString()}`);
	renewTimeoutRef.current = setTimeout(() => {
	  setExpiringSoon(true);
	}, (expirationDate.valueOf() - Date.now()) - (5 * 60 * 1000));
	// attempt to renew 5 minutes before expiration

	expireTimeoutRef.current = setTimeout(() => {
	  console.log("Login token expired");
	  setLoginToken('expired');
	  set('savedLoginToken', 'expired');
	}, (expirationDate.valueOf() - Date.now()));
      }
      setLoginToken(token);
      set('savedLoginToken', token);
    }
    catch (e) {
      console.log("Error verifying token:");
      console.dir(e);
    }
  }, [
    setLoginToken,
    setExpiringSoon,
    setExpirationDate,
    renewTimeoutRef,
  ]);
  useEffect(() => {
    get('savedLoginToken').then((token: string | undefined) => {
      updateLoginToken(token);
    });
  }, [
    updateLoginToken,
  ]);
  return (
    <LoginTokenContext.Provider
      value={{ loginToken }}
    >
      <SetLoginTokenContext.Provider
	value={{
	  setLoginToken: updateLoginToken,
	  expiringSoon: (expirationDate === undefined ? false : expiringSoon),
	  expirationDate,
	} as SetLoginTokenContext}
      >
	<GoogleOAuthProvider
	  clientId='110844648884-f9h2llq4pls4dpqunmmnhgb3b1netorl.apps.googleusercontent.com'
	  children={children}
	/>
      </SetLoginTokenContext.Provider>
    </LoginTokenContext.Provider>
  );
};

export interface LoginPageProps {
}

const LoginPage = ({
}: LoginPageProps) => {
  const {
    loginToken,
  } = useContext(LoginTokenContext);
  const {
    setLoginToken,
  } = useContext(SetLoginTokenContext);
  const onSuccess = useCallback((response: CredentialResponse) => {
    setLoginToken(response.credential);
  }, [
    setLoginToken,
  ]);
  if (loginToken !== undefined) {
    return (
      //TODO continueTo query param or something to configure where to redirect
      <Navigate
	to='/app/'
      />
    );
  }
  return (<>
    <Helmet>
      <title>Log In | Transcribbit Student</title>
    </Helmet>
    <GoogleLogin
      onSuccess={onSuccess}
      text='signin_with'
      type='standard'
      size='large'
    />
    </>);
};

export const MaintainLogin = (
) => {
  const {
    loginToken,
  } = useContext(LoginTokenContext);
  const {
    setLoginToken,
    expiringSoon,
    expirationDate,
  } = useContext(SetLoginTokenContext);
  const [displayManualSigninMessage, setDisplayManualSigninMessage] = useState(false);
  const attemptCountRef = useRef(0);
  const onPromptMomentNotification = useCallback((n: PromptMomentNotification) => {
    if (n.isSkippedMoment()) {
      setDisplayManualSigninMessage(true);
    }
  }, [
    setDisplayManualSigninMessage,
  ]);
  const onAutoSigninTimeout = useCallback((attemptCount: number) => () => {
    if (attemptCountRef.current === attemptCount) {
      setDisplayManualSigninMessage(true);
    }
  }, [
    setDisplayManualSigninMessage,
    attemptCountRef,
  ]);
  useEffect(() => {
    if (expiringSoon) {
      attemptCountRef.current++;
      const timeout = setTimeout(
	onAutoSigninTimeout(attemptCountRef.current),
	// no documentation seems to exist of how long an auto sign in takes
	// let's hope it's not more than 30 seconds
	30 * 1000,
      );
      return () => clearTimeout(timeout);
    }
  }, [
    expiringSoon,
    onAutoSigninTimeout,
    attemptCountRef,
  ]);
  const onSuccess = useCallback((response: CredentialResponse) => {
    setLoginToken(response.credential);
  }, [
    setLoginToken,
  ]);

  // disabled so people don't get kicked out of lectures
  /*
  if (loginToken === 'expired') {
    return (<>
      <Navigate to='/login/' />
    </>);
  }
 */

  if (expiringSoon) {
    return (<div className='maintainSignin'>
      {displayManualSigninMessage ? (<>
	{`Please sign in by ${expirationDate!.toLocaleTimeString()} to stay connected`}
      </>) : (<>
	{"Renewing your authentication..."}
      </>)}
      <GoogleLogin
	onSuccess={onSuccess}
	useOneTap
	auto_select
      />
    </div>);
  }
  return (<></>);
};
export const MaintainLoginWrapper = () => (<>
  {/* disabled until we figure out the correct version for the new oauth flow, if any
  <MaintainLogin />
   */}
  <Outlet />
</>);
export default LoginPage;
