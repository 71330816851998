import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  SidebarBubble,
} from "./Sidebar";
import {
  Question as Question_,
} from "./alertycommon/types";
export interface QuestionData {
  id: string;
  initialText?: string;
  focusOnMount?: true;
  /**
   * Incrementing this will cause this question's input to become focused.
   */
  refocusCount?: number;
}
export interface QuestionProps extends QuestionData {
  /**
   * The elements this is a question about. The question bubble will be placed so its center is
   * at the same height as the center of these elements.
   */
  attachmentElements?: HTMLElement[];
  /**
   * The start time of the content this is a question about. Used when asking and as a
   * SidebarBubble sortTiebreaker.
   */
  startTime: number;
  /**
   * The end time of the content this is a question about. Used when asking.
   */
  endTime: number;
  deleteQuestion: (id: string) => void;
  questionContent: Map<string, string>;
  askQuestion: (question: Question_) => void;
}
const Question = ({
  id,
  initialText,
  focusOnMount,
  attachmentElements,
  refocusCount,
  deleteQuestion,
  startTime,
  endTime,
  questionContent,
  askQuestion,
}: QuestionProps) => {
  const attachmentHeight = useMemo(() => {
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    for (const element of attachmentElements ?? []) {
      const top = element.getBoundingClientRect().top + document.documentElement.scrollTop;
      const bottom = element.getBoundingClientRect().bottom + document.documentElement.scrollTop;
      if (top < min) min = top;
      if (bottom > max) max = bottom;
    }
    if (min === Number.POSITIVE_INFINITY || max === Number.NEGATIVE_INFINITY) {
      return undefined;
    }
    return (min / 2) + (max / 2);
  }, [
    attachmentElements,
    attachmentElements?.map(element => element.getBoundingClientRect().toJSON()),
  ]);

  const [content, setContent] = useState(initialText ?? "");
  const [asked, setAsked] = useState(!!initialText);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const handleChange = useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
    setContent(event.currentTarget.value);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [
    setContent,
    textAreaRef,
  ]);
  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!asked) {
      questionContent.set(id, content);
      askQuestion({
        id,
        startTime,
        endTime,
        questionText: content,
      });
      //TODO send question to teacher
      setAsked(true);
    }
  }, [
    asked,
    questionContent,
    id,
    content,
    startTime,
    endTime,
    askQuestion,
  ]);
  useEffect(() => {
    if (refocusCount !== undefined) {
      textAreaRef.current?.focus();
    }
  }, [textAreaRef, refocusCount]);
  const [focusedOnMount, setFocusedOnMount] = useState(false);
  useEffect(() => {
    if (focusOnMount && !focusedOnMount) {
      if (attachmentHeight !== undefined) {
        setFocusedOnMount(true);
      }
      textAreaRef.current?.focus();
    }
  }, [textAreaRef, focusOnMount, attachmentHeight]);
  return (<SidebarBubble
    className='question'
    id={id}
    target={attachmentHeight ?? Number.POSITIVE_INFINITY}
    sortTiebreaker={startTime}
  >
    <form onSubmit={handleSubmit}>
      <textarea
        onChange={handleChange}
        value={content}
        ref={textAreaRef}
        rows={content?.length ? undefined : 1}
        readOnly={asked}
      />
      {asked ? (<></>) : (<>
      <div className='questionButtons'>
        <input type='submit' value='Ask' />
        <button onClick={() => deleteQuestion(id)}>
          Cancel
        </button>
      </div>
      </>)}
    </form>
  </SidebarBubble>);
};
export default Question;
