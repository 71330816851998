import {
  Message,
} from "./types";
import {
  useEffect,
  useState,
  useReducer,
  useCallback,
} from 'react';
let registered : (((m : Message) => void) | null)[] = [];
export function register(callback : (m : Message) => void) : number {
  // find the first empty slot
  let firstNull = registered.findIndex(v => v === null);

  // if there's none, add a new slot
  if (firstNull === -1)
    return registered.push(callback) - 1;

  registered[firstNull] = callback;
  return firstNull;
}

export function unregister(id : number) : void {
  registered[id] = null;
}

export function broadcastMessage(m : Message) {
  registered.map(c => c && c(m));
}

export function parseMessage(state : Message[], msg : Message) : Message[] {
  let phraseToUpdate = msg.phrase.phraseNumber;
  if (phraseToUpdate < 0) {
    console.log("Negative phrase number?");
    return state;
  }
  if (state.length > phraseToUpdate && msg.msgNumber < state[phraseToUpdate].msgNumber) return state;

  let newState = state.slice();
  newState[phraseToUpdate] = {
    msgNumber: msg.msgNumber,
    phrase: {
      phraseNumber: msg.phrase.phraseNumber,
      segments: msg.phrase.segments.map(seg => ({
	...seg,
      })),
    }
  };
  for (let index = 0; index < newState.length; index++) {
    if (newState[index] !== undefined) continue;
    newState[index] = {
      phrase: { phraseNumber: index, segments: [] },
      msgNumber: 0,
    };
  }
  return newState;
}

export function useSubscription() {
  const [state, receiveMessage] = useReducer(parseMessage, []);
  useEffect(() => {
    let id = register(receiveMessage);
    return () => unregister(id);
  }, []);
  return state;
}
export function useResettableSubscription() {
  const [state, updateState] =  useSubscriptionWithReducer(
    useCallback((state, update: 'reset' | Message) => {
      if (update === 'reset') return [];
      return false;
    }, [])
  );
  return [
    state,
    useCallback(() => updateState('reset'), [updateState]),
  ] as const;
  /*
  const [state, updateState] = useReducer((state: Message[], update: 'reset' | Message) => {
    if (update === 'reset') return [];
    return parseMessage(state, update);
  }, []);
  useEffect(() => {
    let id = register(updateState);
    return () => unregister(id);
  }, []);
  return [state, () => updateState('reset')] as const;
  */
}
export function useSubscriptionWithReducer<Update>(
  /**
   * Custom reducer allowing for custom updates to state.
   * Return false to use normal update logic for Message objects.
   * May accept custom types, but must never return false if given a non-Message.
   * (Tried to enforce this at the type level but couldn't get it to accept a valid one)
   */
  reducer: (
    ((state: Message[], update: Message | Update) => Message[] | false)
  ),
) {
  const [state, updateState] = useReducer((state: Message[], update: Update | Message) => {
    const result = reducer(state, update);
    if (result !== false) return result;
    return parseMessage(state, update as Message);
  }, []);
  useEffect(() => {
    let id = register(updateState);
    return () => unregister(id);
  }, []);
  return [state, updateState] as const;
}
