import React, {
  useRef,
  useCallback,
  useEffect,
} from 'react';

interface StickToBottomProps {
  /**
   * Whenever any of these change, if the user's last scroll took them to the bottom
   * of the page, scroll back to the bottom.
   */
  watchedValues: unknown[];
  /**
   * The distance from the bottom of the page at which to start sticking.
   */
  threshold: number;
}
const StickToBottom = ({
  watchedValues,
  threshold,
}: StickToBottomProps) => {
  const atBottomRef = useRef(true);
  const onScroll = useCallback(() => {
    console.log(`scrollHeight: ${document.body.scrollHeight}, innerHeight: ${window.innerHeight}, scrollY: ${window.scrollY}`);
    atBottomRef.current = 
      (document.body.scrollHeight - window.innerHeight - window.scrollY) < threshold + 1;
    console.log(`setting atBottom to ${atBottomRef.current}`);
  }, [
    atBottomRef,
    threshold,
  ]);
  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => { document.removeEventListener('scroll', onScroll); };
  }, [
    onScroll,
  ]);
  useEffect(() => {
    console.log(`atBottom: ${atBottomRef.current}`);
    if (atBottomRef.current) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [
    atBottomRef,
    ...watchedValues,
  ]);
  return (
    null
  );
};
export default StickToBottom;
