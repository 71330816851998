import { Message, Phrase, PhraseSegment } from "./alertycommon/types";

import sampleText from "./sampleText";
import evoBio from "./sampleEvoBio";
import physics from "./samplePhysics";
import history from "./sampleHistory";
import lit from "./sampleLit";
import oChem from "./sampleOChem";
const samples = {
  'SAMPLE_LECTURE': sampleText,
  'SAMPLE_EVOBIO': evoBio,
  'SAMPLE_PHYSICS': physics,
  'SAMPLE_HISTORY': history,
  'SAMPLE_LIT': lit,
  'SAMPLE_OCHEM': oChem,
} as const;
export const validSampleLectures = Object.getOwnPropertyNames(samples) as ReadonlyArray<keyof typeof samples>;

// All of this is just to load, parse, and broadcast the sample transcript.
class Token implements PhraseSegment {
  public constructor(
    public content : string,
    public emphasized : boolean = false,
    public emphasisRisingEdge : boolean = false,
    public startTime = 0,
    public endTime = 0
  ) {
  }
}
let currentTime = 0;
let chunks : Token[][] = [];
let currentChunk : Token[] = [];
let currentToken : Token = new Token("");
let emphasisActive = false;
const load = (lecture: keyof typeof samples) => {
  console.log(`Loading lecture ${lecture}`);
  currentTime = 0;
  chunks = [];
  currentChunk = [];
  currentToken = new Token("", false, false, 0, ++currentTime);
  emphasisActive = false;
  for (let ch of samples[lecture]) {
    switch (ch) {
      case ' ':
	currentToken.content += " ";
	currentChunk.push(currentToken);
	currentToken = new Token("", emphasisActive, false, ++currentTime, ++currentTime);
	break;
      case '[':
	//currentChunk = [];
	break;
      case ']':
      /*
	if (currentToken.content.length > 0) {
	  currentChunk.push(currentToken);
	  currentToken = new Token("", emphasisActive, false, ++currentTime, ++currentTime);
	}
	chunks.push(currentChunk);
	console.log(`Pushing chunk of length ${currentChunk.length}`);
      */
	break;
      case '{':
	if (currentToken.content.length > 0) {
	  currentChunk.push(currentToken);
	  currentToken = new Token("", true, true, ++currentTime, ++currentTime);
	}
	else {
	  currentToken.emphasisRisingEdge = true;
	}
	emphasisActive = true;
	break;
      case '}':
	emphasisActive = false;
	if (currentToken.content.length > 0) {
	  currentChunk.push(currentToken);
	  currentToken = new Token("", false, false, ++currentTime, ++currentTime);
	}
	else {
	  currentToken.emphasized = false;
	}
	break;
      case '|':
	if (currentToken.content.length > 0) {
	  currentChunk.push(currentToken);
	  currentToken = new Token("", emphasisActive, false, ++currentTime, ++currentTime);
	}
	chunks.push(currentChunk);
	currentChunk = [];
	console.log(`Pushing chunk of length ${currentChunk.length}`);
	currentToken.content += "\n";
	break;
      case '\n':
	console.log("Skipping newline");
	break;
      default:
	currentToken.content += ch;
	break;
    }
  }
}

let lastMessage = -1;
let currentPhrase = 0;
let currentWord = 0;

let timeoutId: number = 0;

/**
 * Called when the transcript screen is loaded and ready to receive messages.
 */
export const demoStart = (
  /**
   * The ID of the lecture the student is joining
   */
  lectureId: string,
  /**
   * Call with messages representing new transcript data.
   */
  broadcastMessage: (message: Message) => void,
  /**
   * Call when the device should vibrate to alert the user.
   */
  vibrate: () => void
): void => {
  if (validSampleLectures.some(s => s === lectureId)) load(lectureId as keyof typeof samples);
  else load('SAMPLE_LECTURE');
  // for testing, broadcast the sample text token by token
  // at a rate based on the lengths of the tokens
  // and whether the preceding token contains punctuation
  console.log(currentWord);
  console.log(currentPhrase);
  console.dir(chunks);
  const PUNCTUATION_MS = 100;
  const CHAR_MS = 30;
  const BASE_MS = 100;
  const punctuationRegex = /[?.!,]/;
  const broadcastToken = () => {
    if (chunks[currentPhrase][currentWord].emphasisRisingEdge) {
      // when an emphasized section begins, vibrate the device
      console.log("attempting to notify");
      vibrate();
    }
    const hasPunctuation = punctuationRegex.test(chunks[currentPhrase][currentWord].content);
    let currentSegments = chunks[currentPhrase].slice(0, ++currentWord);

    let startNewPhrase = false;
    broadcastMessage({
      msgNumber: ++lastMessage,
      phrase: {
	phraseNumber: currentPhrase,
	segments: currentSegments,
      },
    });
    if (currentWord >= chunks[currentPhrase].length) {
      currentWord = 0;
      startNewPhrase = true;
    }
    if (startNewPhrase) currentPhrase++;
    waitForToken(hasPunctuation ? PUNCTUATION_MS : 0);
  };
  const waitForToken = (baseDelay: number) => {
    if (currentPhrase >= chunks.length) return;
    timeoutId = setTimeout(broadcastToken, baseDelay + BASE_MS + (chunks[currentPhrase][currentWord].content.length * CHAR_MS)) as unknown as number;
  }
  waitForToken(0);
};

/**
 * Called when the transcript screen is unloaded and messages should no longer be sent.
 */
export const demoEnd = (): void => {
  if (timeoutId !== 0) clearTimeout(timeoutId);
  timeoutId = 0;
  lastMessage = -1;
  currentPhrase = 0;
  currentWord = 0;
}
