import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  useLoaderData,
  redirect,
} from 'react-router-dom';
import {
  decompressFromEncodedURIComponent,
} from 'lz-string';
import {
  checkedLoader,
  anyString,
} from "../utils";
import {
  importLecture,
} from "../db";
import {
  knownLecturesContextValue,
} from "./LectureListPage";

export interface LoaderParams {
  params: {
    encodedURL: string;
  };
}
export const loader = checkedLoader(
  {
    encodedURL: anyString,
  },
  async ({ params: {
    encodedURL,
  }}: LoaderParams): Promise<Response> => {
    const url = decompressFromEncodedURIComponent(encodedURL);
    if (url === null) {
      throw new Response("Invalid URI to import from", {
        status: 400,
      });
    }
    const response = await fetch(`/external/${encodedURL}/`);
    if (!response.ok) throw response;
    const blob = await response.blob();
    try {
      const imported = await importLecture(blob, true);
      knownLecturesContextValue.value.addKnownLecture(imported);
      return redirect(`/app/lecture/saved/${imported.id}/`);
    }
    catch (e) {
      if (typeof e === 'string') {
        throw new Response(e, {
          status: 422,
        });
      }
      console.log("Unexpected error");
      console.log(e);
      throw e;
    }
  }
);
