import {
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react';
import {
  get,
  getMany,
  set,
  setMany,
  update,
} from 'idb-keyval';
import FileSaver from 'file-saver';
import {
  compressToUint8Array,
  decompressFromUint8Array,
} from 'lz-string';

import {
  Message,
  Highlight,
  Question,
  Phrase,
  PhraseSegment,
} from "./alertycommon/types";
import {
  validSampleLectures,
} from "./sampleLectures";
import {
  Lecture,
  SavedLecture,
} from "./fetchLectures";
import {
  displayName,
} from "./routes/LectureListPage";
import {
  error,
  tryAccess,
} from "./utils";

export interface SavedLectureDB {
  saved: true;
  id: string;
  className: string;
  teacherName?: string;
  startDate?: string;
  customName?: string;
}
export const serializeLecture = (lecture: SavedLecture): SavedLectureDB => ({
  ...lecture,
  startDate: lecture.startDate?.toISOString()
})
export const deserializeLecture = (lecture: SavedLectureDB): SavedLecture => ({
  ...lecture,
  startDate: lecture.startDate === undefined ? undefined
    : new Date(lecture.startDate),
})

export interface SavedNote {
  id: string;
  range: Highlight;
  content: string;
}
export interface SavedQuestion {
  id: string;
  range: Highlight;
  content: string;
}

export const loadLecture = async (
  lectureID: string,
): Promise<{
  messages: Message[],
  highlights: Highlight[],
  questions: Question[],
  notes: SavedNote[],
}> => {
  const [
    messages,
    highlights,
    savedQuestions,
    notes,
  ] = (await getMany([
    `${lectureID}-transcript`,
    `${lectureID}-highlights`,
    `${lectureID}-questions`,
    `${lectureID}-notes`,
  ])).map(v => v ?? []);
  const questions = (savedQuestions as (Question | SavedQuestion)[]).map<Question>(q =>
    ( // migrate old SavedQuestion interface to Question
      'range' in q ? {
	id: q.id,
	questionText: q.content,
	startTime: q.range[0],
	endTime: q.range[1],
      }
      : q
    ));
  return {
    messages,
    questions,
    highlights,
    notes,
  };
};

export const indexLecture = (
  id: string,
  className: string,
  teacherName?: string,
  startDate?: Date,
  customName?: string,
) => {
  console.log(`Indexing lecture ${id}`);
  return update('savedLectures', (lectures: SavedLectureDB[] = []) => {
    const newLecture: SavedLectureDB = {
      saved: true,
      id,
      className,
      teacherName,
      startDate: startDate?.toISOString(),
      customName,
    };
    const updated = lectures.slice();
    const index = lectures.findIndex(l => l.id === id);
    if (index === -1) {
      console.log("Adding to index");
      updated.push(newLecture);
    }
    else {
      console.log("Updating existing index entry");
      updated[index] = {
	...lectures[index],
	...newLecture,
      };
    }
    return updated;
  }).then(async () => {
    const savedLectures = await get('savedLectures');
    console.dir(savedLectures);
  });
};
export const loadSavedLectures = async (): Promise<SavedLecture[]> => {
  let serialized = ((await get('savedLectures')) as SavedLectureDB[] | undefined);
  // migration: if any sample lectures got indexed, deindex them, then refetch the index
  if (serialized?.some(l => l.id.startsWith('SAMPLE_'))) {
    await update<SavedLectureDB[] | undefined>('savedLectures', saved => saved
      ?.filter(l => !l.id.startsWith('SAMPLE_'))
    );
    serialized = await get('savedLectures');
  }
  return serialized
    ?.map(deserializeLecture)
    ?? [];
};

export const useSaveLectureOnUnmount = (...args: [
  lectureID: string,
  state: Message[],
  highlights: Highlight[],
  notes: [id: string, range: Highlight][],
  noteContent: Map<string, string>,
  questions: [id: string, range: Highlight][],
  questionContent: Map<string, string>,
  lecture?: Lecture,
]) => {
  const [startDate, setStartDate] = useState(new Date());
  const [
    lectureID,
    state,
    _,
    __,
    ___,
    ____,
    _____,
    lecture,
  ] = args;
  useEffect(() => {
    if (state.length === 0) return;
    if (lectureID.startsWith('SAMPLE_')) return;
    if (lecture) {
      indexLecture(
	lectureID,
	lecture.className,
	lecture.teacherName,
	startDate,
	(lecture.saved && lecture.customName) || undefined,
      );
    }
    else {
      indexLecture(
	lectureID,
	"Unknown Lecture",
	undefined,
	startDate,
      );
    }
  }, [
    lectureID,
    state,
    lecture,
    startDate,
  ]);
  const argsRef = useRef<typeof args>(args);
  useEffect(() => { argsRef.current = args; }, args);
  const save = useCallback(() => {
    const [
      lectureID,
      state,
      highlights,
      notes,
      noteContent,
      questions,
      questionContent,
      lecture,
    ] = argsRef.current;
    if (lectureID.startsWith('SAMPLE_')) {
      console.log("Skipping save for sample lecture");
      return;
    }
    console.log(`Saving ${state.length} messages, ${highlights.length} highlights, ${questions.length} questions, and ${notes.length} notes`);
    if (state.length > 0) {
      const savedNotes = notes.flatMap(([id, range]): SavedNote[] => (
	noteContent.has(id) ? [{
	  id,
	  range,
	  content: noteContent.get(id)!,
	}]
	: []
      ));
      const savedQuestions = questions.flatMap(([id, range]): Question[] => (
	questionContent.has(id) ? [{
	  id,
	  startTime: range[0],
	  endTime: range[1],
	  questionText: questionContent.get(id) ?? "",
	}]
	: []
      ));
      setMany([
	[`${lectureID}-transcript`, state],
	[`${lectureID}-highlights`, highlights],
	[`${lectureID}-notes`, savedNotes],
	[`${lectureID}-questions`, savedQuestions],
      ]);
    }
  }, [
    argsRef,
    startDate,
  ]);
  // call save on unmount
  useEffect(() => save, [save]);
  useEffect(() => {
    window.addEventListener('beforeunload', save);
    return () => { window.removeEventListener('beforeunload', save) };
  }, [
    save,
  ]);
};

export interface LectureExportBody extends SavedLectureDB {
  transcript: Message[];
  highlights: Highlight[];
  notes: SavedNote[];
  questions: Question[];
}
export const exportLecture = async (
  lecture: SavedLecture,
) => {
  const [
    transcript,
    highlights,
    notes,
    questions,
  ] = (await getMany([
    `${lecture.id}-transcript`,
    `${lecture.id}-highlights`,
    `${lecture.id}-notes`,
    `${lecture.id}-questions`,
  ])).map(v => v ?? []);
  const body = JSON.stringify({
    ...serializeLecture(lecture),
    transcript,
    highlights,
    notes,
    questions,
  });
  const bodyCompressed = compressToUint8Array(body)
  const header = 'v1.0.0;lz-string/uint8:';
  const headerLength = new Uint32Array([header.length]);
  const blob = new Blob([
    'FROG',
    headerLength,
    header,
    bodyCompressed,
  ], {
    type: "application/octet-stream",
  });
  FileSaver.saveAs(blob, `${displayName(lecture)}.ribbit`);
}

const versionRegex = /^v([A-Za-z0-9\.\-]+);/;
const v1_0_0HeaderRegex = /([^:]+):/;
export const importLecture = async (
  file: Blob,
  sanitize = false,
) => {
  try {
    const magic = file.slice(0, 4);
    const frog = await magic.text();
    if (frog !== 'FROG') throw new Error("incorrect type identifier");
    const headerLength = new Uint32Array(
      await file.slice(4, 8).arrayBuffer()
    )[0];
    const header = await file.slice(8, headerLength + 8).text();
    const [versionHeader, versionString] = versionRegex.exec(header) ?? ["", "invalid"];
    if (versionString === '1.0.0') {
      const [_, compressionFormat] = v1_0_0HeaderRegex.exec(
	header.slice(versionHeader.length)
      ) ?? ["", "invalid"];
      if (compressionFormat === "invalid") throw new Error("invalid 1.0.0 file");
      const bodyBlob = file.slice(headerLength + 8);
      const bodyString =
	compressionFormat === 'raw/utf8' ? await bodyBlob.text()
        : compressionFormat === 'lz-string/uint8' ? decompressFromUint8Array(
	  new Uint8Array(await bodyBlob.arrayBuffer()),
	)
	: error(`unsupported compression format: ${compressionFormat}`)
      ;
      let body = JSON.parse(bodyString) as LectureExportBody;
      if (sanitize) {
	type MapUntrusted<T> = {
	  [TProperty in keyof T]: Untrusted<T[TProperty]>;
	}
	type MapUntrustedTuple<T extends readonly [...any[]]> = {
	  [Index in keyof T]: Untrusted<T[Index]>;
	} & {length: T['length']};
	type Untrusted<T> = (
	  T extends readonly [...any[]] ? (
	    T extends (number extends T['length'] ? [] : any) ? MapUntrustedTuple<T> | {}
	      : T extends Array<infer U> ? Array<Untrusted<U>> | {}
	    : ({} | []))
	  : (Partial<MapUntrusted<T>> & { [key: string]: unknown }) | []
	) | null | string | number | boolean;
	/*
	type Test<T> = (
	  T extends [...any[]] ? (
	    T extends (number extends T['length'] ? [] : any) ? `MapUntrustedTuple<T> | {}`
	      : T extends Array<infer U> ? `Array<Untrusted<U>> | {}`
	    : `({} | [])`)
	  : `(Partial<MapUntrusted<T>> & { [key: string]: unknown }) | []`
	);
	type MapTest<T> = {
	  [TProperty in keyof T]: Test<T[TProperty]>;
	}
	type MapTestTuple<T extends [...any[]]> = {
	  [Index in keyof T]: Test<T[Index]>;
	} & {length: T['length']};
	type Test2<T> = (
	  T extends [...any[]] ? (
	    T extends (number extends T['length'] ? [] : any) ? MapTestTuple<T> | {}
	      : T extends Array<infer U> ? Array<Test<U>> | {}
	    : ({} | []))
	  : (Partial<MapTest<T>> & { [key: string]: unknown }) | []
	) | null | string | number | boolean;
	type MapTest2<T> = {
	  [TProperty in keyof T]: Test2<T[TProperty]>;
	}
	type MapTest2Tuple<T extends [...any[]]> = {
	  [Index in keyof T]: Test2<T[Index]>;
	} & {length: T['length']};
	type Test3<T> = (
	  T extends [...any[]] ? (
	    T extends (number extends T['length'] ? [] : any) ? MapTest2Tuple<T> | {}
	      : T extends Array<infer U> ? Array<Test2<U>> | {}
	    : ({} | []))
	  : (Partial<MapTest2<T>> & { [key: string]: unknown }) | []
	) | null | string | number | boolean;
	let foo: Untrusted<Question> = null as any;
	if (typeof foo === 'object' && foo !== null && !(foo instanceof Array)) {
	  if (typeof foo.answer === 'object' && foo.answer !== null && !(foo.answer instanceof Array)) {
	  }
	}
	*/
	type Sanitizer<T> = (v: Untrusted<T>) => T | null
	const arrayOf = <T>(
	  element: Sanitizer<T>,
	): Sanitizer<T[]> => v => v instanceof Array
	  ? v.map(element)
	    .filter((e): e is T => e !== null)
	  : null;
	type TupleSanitizers<T extends readonly [...any[]]> =
	  T extends (number extends T['length'] ? [] : any) ? ({
	  [Index in keyof T]: Sanitizer<T[Index]>;
	} & {length: T['length']}) : never;
	const tup = <T extends readonly [...any[]]>(
	  structure: TupleSanitizers<T>
	): T extends (number extends T['length'] ? [] : any)
	  ? Sanitizer<T>
	  : unknown => ((t: MapUntrustedTuple<T>) => {
	  if (typeof (t as any) !== 'object') return null;
	  if ((t as any) === null) return null;
	  if (!((t as any) instanceof Array)) return null;
	  const result: any[] = [];
	  for (let i = 0; i < structure.length; i++) {
	    if (i >= (t as any as any[]).length) return null;
	    const sanitized = structure[i]((t as any as any[])[i] as any);
	    if (sanitized === null) return null;
	    result.push(sanitized);
	  }
	  return result as any as T;
	}) as any;
	type ObjectSanitizers<T> = {
	  [TProperty in keyof T]: Sanitizer<T[TProperty]>
	};
	const obj = <T>(
	  structure: ObjectSanitizers<T>
	): Sanitizer<T> => o => {
	  if (typeof o !== 'object') return null;
	  if (o === null) return null;
	  if (o instanceof Array) return null;
	  const entries =
	    (Object.entries(structure)as any as [string, Sanitizer<unknown>][])
	    .map(([name, sanitizer]) => [name, sanitizer(tryAccess(o, name as any, undefined) as any)] as const);
	  for (const [name, value] of entries) {
	    if (value === null) {
	      console.log(`Validation failed for field ${name}`);
	      console.log(tryAccess(o, name as any, undefined));
	      console.log(structure[name as keyof T])
	    }
	  }
	  if (entries.some(([name, value]) => value === null)) return null;
	  return Object.fromEntries(entries) as any as T;
	};
	const string_: Sanitizer<string> = v => typeof v === 'string' ? v : null;
	const number_: Sanitizer<number> = v => typeof v === 'number' ? v : null;
	const boolean_: Sanitizer<boolean> = v => typeof v === 'boolean' ? v : null;
	const Optional = <T>(
	  sanitizer: Sanitizer<T>,
	): Sanitizer<T | undefined> => v => v === undefined
	  ? undefined
	  : sanitizer(v as Untrusted<T>);
	const PhraseSegment_ = obj<PhraseSegment>({
	  emphasized: boolean_,
	  content: string_,
	  startTime: number_,
	  endTime: number_,
	});
	const Phrase_ = obj<Phrase>({
	  phraseNumber: number_,
	  segments: arrayOf(PhraseSegment_),
	});
	const Message_ = obj<Message>({
	  msgNumber: number_,
	  phrase: Phrase_,
	});
	const Highlight_ = tup<Highlight>([number_, number_]);
	const Question_ = obj<Question>({
	  id: string_,
	  startTime: number_,
	  endTime: number_,
	  questionText: string_,
	  answer: Optional(
	    obj({
	      startTime: number_,
	      endTime: number_,
	    })
	  ),
	});
	const SavedNote_ = obj<SavedNote>({
	  id: string_,
	  range: Highlight_,
	  content: string_,
	});
	const literal = <T>(value: T): Sanitizer<T> => v => (v as any) === value ? value : null;
	const optStr = Optional(string_);
	const LectureExportBody_ = obj<LectureExportBody>({
	  transcript: arrayOf(Message_),
	  highlights: arrayOf(Highlight_),
	  questions: arrayOf(Question_),
	  notes: arrayOf(SavedNote_),
	  id: string_,
	  saved: literal(true),
	  className: string_,
	  teacherName: optStr,
	  startDate: optStr,
	  customName: optStr,
	});
	const sanitized = LectureExportBody_({...body, saved: true} as Untrusted<LectureExportBody>);
	if (sanitized === null) {
	  console.log(JSON.stringify(body, undefined, 2));
	  throw "One or more missing or invalid fields in lecture file";
	}
	body = sanitized;
      }
      const {
	transcript,
	highlights,
	questions,
	notes,
	id,
	className,
	teacherName,
	startDate,
	customName,
      } = body;
      await setMany([
	[`${id}-transcript`, transcript],
	[`${id}-highlights`, highlights],
	[`${id}-notes`, notes],
	[`${id}-questions`, questions],
      ]);
      const deserialized = deserializeLecture({
	saved: true,
	id,
	className,
	teacherName,
	startDate,
	customName,
      });
      await indexLecture(
	id,
	className,
	teacherName,
	deserialized.startDate,
	customName,
      );
      return deserialized;
    }
    else {
      throw "Unsupported version";
    }
  }
  catch (e) {
    console.log(e);
    const message = (
      typeof e === 'string' ? e
      : typeof e === 'object' && e instanceof Error ? e.message
      : `${e}`
    );
    throw `Invalid lecture file: ${message}`
  }
}
