import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import {
  Highlight,
} from "./alertycommon/types";
import {
  SidebarBubble,
} from "./Sidebar";

const expectedNodeNames = new Set([
  '#text',
  'STRONG',
  'SPAN',
]);
export const getDataAttributeFromText = (node: Node, attribute: string): string | undefined => {
  if (!expectedNodeNames.has(node.nodeName)) {
    console.log(`Unexpected node name: ${node.nodeName}`);
    return undefined;
  }
  if (node instanceof HTMLElement && node.dataset[attribute] !== undefined) {
    return node.dataset[attribute];
  }
  if (node.parentNode) return getDataAttributeFromText(node.parentNode, attribute);
  return undefined;
}
interface SelectionActionMenuProps {
  addHighlight: (highlight: Highlight) => void,
  addNote: (range: Highlight) => void,
  addQuestion: (range: Highlight) => void,
}
const SelectionActionMenu = ({
  addHighlight,
  addNote,
  addQuestion,
}: SelectionActionMenuProps) => {
  const [selectionTimeRange, setSelectionTimeRange] = useState<readonly [number, number]>();
  const [selectionCenterHeight, setSelectionCenterHeight] = useState<number>();
  const onHighlightClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    console.log("onHighlightClick");
    e.preventDefault();
    if (selectionTimeRange !== undefined) {
      addHighlight(selectionTimeRange);
    }
    document.getSelection()?.collapse(null);
  }, [
    addHighlight,
    selectionTimeRange,
  ]);
  const onAddNoteClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    console.log("onAddNoteClick");
    e.preventDefault();
    if (selectionTimeRange !== undefined) {
      addNote(selectionTimeRange);
    }
    document.getSelection()?.collapse(null);
  }, [
    addNote,
    selectionTimeRange,
  ]);
  const onAddQuestionClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    console.log("onAddQuestionClick");
    e.preventDefault();
    if (selectionTimeRange !== undefined) {
      addQuestion(selectionTimeRange);
    }
    document.getSelection()?.collapse(null);
  }, [
    addQuestion,
    selectionTimeRange,
  ]);

  const onSelectionChange = useCallback(() => {
    const selection = document.getSelection();
    if (!selection || selection.isCollapsed) {
      setSelectionCenterHeight(undefined);
      return;
    }
    const rect = selection.getRangeAt(0).cloneRange().getBoundingClientRect();
    setSelectionCenterHeight((rect.top / 2) + (rect.bottom / 2) + document.documentElement.scrollTop);
    const anchorNode = selection.anchorNode;
    const focusNode = selection.focusNode;
    if (!anchorNode || !focusNode) {
      setSelectionTimeRange(undefined);
      return;
    }

    const anchorStartTime = getDataAttributeFromText(anchorNode, 'startTime');
    const anchorEndTime = getDataAttributeFromText(anchorNode, 'endTime');

    const focusStartTime = getDataAttributeFromText(focusNode, 'startTime');
    const focusEndTime = getDataAttributeFromText(focusNode, 'endTime');
    if (
      anchorStartTime === undefined || anchorEndTime === undefined
      || focusStartTime === undefined || focusEndTime === undefined
    ) {
      setSelectionTimeRange(undefined);
      return;
    }

    let firstNodeTimes = [Number.parseFloat(anchorStartTime), Number.parseFloat(anchorEndTime)];
    let secondNodeTimes = [Number.parseFloat(focusStartTime), Number.parseFloat(focusEndTime)];

    if (secondNodeTimes[1] < firstNodeTimes[0]) {
      [firstNodeTimes, secondNodeTimes] = [secondNodeTimes, firstNodeTimes];
    }
    setSelectionTimeRange([firstNodeTimes[0], secondNodeTimes[1]]);
    //TODO get start/end time from the text nodes somehow
  }, []);
  useEffect(() => {
    document.addEventListener('selectionchange', onSelectionChange);
    return () => document.removeEventListener('selectionchange', onSelectionChange);
  }, [onSelectionChange]);
  return (<SidebarBubble
    className={selectionCenterHeight ? 'selectionActionMenu' : 'actionMenuHidden'}
    id='SelectionActionMenu'
    target={selectionCenterHeight ?? Number.POSITIVE_INFINITY}
    sortTiebreaker={selectionTimeRange?.[0]}
  >
    <button onClick={onHighlightClick}>
      Highlight
    </button>
    <br />
    <button onClick={onAddNoteClick}>
      {/* TODO: if overlap, change to "Edit Note" */}
      Add Note
    </button>
    <br />
    <button onClick={onAddQuestionClick}>
      {/* TODO: if overlap, change to "Edit Note" */}
      Ask a Question
    </button>
  </SidebarBubble>);
}
export default SelectionActionMenu;
