import {
  Message,
  Phrase,
  PhraseSegment,
  Question,
  Highlight,
} from './types';
export const highlightsOverlap = ([startA, endA]: Highlight, [startB, endB]: Highlight) => (
  (startA <= startB && startB < endA) // B starts during A, but not just as it's ending
  || (startA < endB && endB <= endA) // B ends during A, but not just as it's starting
  || (startB <= startA && endA <= endB) // A is strictly within B
  || (startA <= startB && endB <= endA) // B is strictly within A
);
export const highlightedBy = (seg: PhraseSegment, [start, end]: Highlight) => (
  (start <= seg.startTime && seg.startTime < end) // segment starts during the highlight, but not just as it's ending
  || (start < seg.endTime && seg.endTime <= end) // segment ends during the highlight, but not just as it's starting
  || (seg.startTime <= start && end <= seg.endTime) // nothing outside the segment is highlighted
);

export const isHighlighted = (seg: PhraseSegment, highlights: Highlight[]) => 
  highlights.some(h => highlightedBy(seg, h));
export const highlightStyles = <StyledHighlight extends {range: Highlight, style: unknown}>(seg: PhraseSegment, highlights: StyledHighlight[]) => 
  highlights.filter(h => highlightedBy(seg, h.range)).map(h => h.style);


export function memoConst<T>(thunk : () => T) : () => T {
  let value : T | null;
  return () => { return value ?? (value = thunk())!;
  };
} 
export function memoEqual<T, U>(func : (arg: T) => U): (arg: T) => U {
  let lastArg : T | null = null;
  let lastValue : U | null = null;
  return arg => {
    if (arg === lastArg) return lastValue ?? (lastValue = func(arg));
    lastArg = arg;
    return (lastValue = func(arg));
  }
}
