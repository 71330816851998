import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  useLoaderData,
  Link,
} from 'react-router-dom';
import {
  v4 as uuidv4,
} from 'uuid';
import {
  useResizeDetector,
} from 'react-resize-detector';
import {
  createGlobalStyle,
} from 'styled-components';
import FileSaver from 'file-saver';
import {
  Helmet,
} from 'react-helmet';

import {
  tryAccess,
  checkedLoader,
  anyString,
  inArray,
} from "../utils";
import {
  useResettableSubscription,
  broadcastMessage,
} from "../alertycommon/phraseSubscriptions";
import {
  Message,
  Phrase as Phrase_,
  PhraseSegment as PhraseSegment_,
} from "../alertycommon/types";
import {
  demoStart,
  demoEnd,
  validSampleLectures,
} from "../sampleLectures";
import SelectionActionMenu, {
} from "../SelectionActionMenu";
import {
  Highlight,
  Question as Question_,
} from "../alertycommon/types";
import {
  highlightedBy,
  highlightsOverlap,
} from "../alertycommon/transcriptUtils";
import {
  useLectureSubscription,
  useVibrationSubscription,
} from "../alertycommon/lectureStream";
import {
  useSendQuestions,
} from "../questionStream";
import {
  socketURLForID,
} from "../multilectureConstants";
import Note, {
  NoteProps,
  NoteData,
} from "../Note";
import Question, {
  QuestionProps,
  QuestionData,
} from "../Question";
import Sidebar, {
} from "../Sidebar";
import StickToBottom from "../StickToBottom";
import {
  loadLecture,
  useSaveLectureOnUnmount,
  SavedQuestion,
  SavedNote,
  loadSavedLectures,
} from "../db";
import {
  displayName,
  KnownLecturesContext,
  knownLecturesContextValue,
} from "./LectureListPage";
import {
  getDataAttributeFromText,
} from "../SelectionActionMenu";

export const lectureTypes = [
  'sample',
  'live',
  'saved',
] as const;
export type LectureType = typeof lectureTypes[number];
export interface LoaderParams {
  params: {
    lectureType: LectureType;
    lectureID: string;
  };
}
export interface CommonLoaderData {
  lectureID: string;
  lectureDisplayName?: string;
  savedHighlights: Highlight[];
  savedNotes: SavedNote[];
  savedQuestions: Question_[];
}
export type LoaderData =
  SampleLectureData
  | LiveLectureData
  | SavedLectureData;
interface SampleLectureData extends CommonLoaderData {
  lectureType: 'sample';
  lectureID: (typeof validSampleLectures)[number];
}
interface LiveLectureData extends CommonLoaderData {
  lectureType: 'live';
  savedTranscript: Message[];
  socketURL: string;
}
interface SavedLectureData extends CommonLoaderData {
  lectureType: 'saved';
  savedTranscript: Message[];
}

export const loader = checkedLoader(
  {
    lectureType: inArray(lectureTypes),
    lectureID: anyString,
  },
  async ({ params: {
    lectureType,
    lectureID,
  }}: LoaderParams): Promise<LoaderData> => {
    const thisLecture =
      knownLecturesContextValue.value.knownLectures.get(`${lectureType} ${lectureID}`)
      ?? await knownLecturesContextValue.value.checkForLecture(lectureType, lectureID);
    const lectureDisplayName = thisLecture && displayName(thisLecture);
    if (lectureType === 'sample') {
      // without this check, malicious URLs can load arbitrary properties of the
      // sampleLectures object, possibly allowing data exfiltration of some sort
      if (inArray(validSampleLectures)(lectureID, 'lectureID')) {
        const {
          highlights,
          questions,
          notes,
        } = await loadLecture(lectureID);
        return {
          lectureType,
          lectureID,
          lectureDisplayName,
          savedHighlights: highlights,
          savedQuestions: questions,
          savedNotes: notes,
        };
      }
      throw "inArray was supposed to throw";
    }
    else if (lectureType === 'live') {
      //TODO wait/check for lecture validity
      const {
        messages,
        highlights,
        questions,
        notes,
      } = await loadLecture(lectureID);
      return {
        lectureType,
        lectureID,
        lectureDisplayName,
        savedHighlights: highlights,
        savedQuestions: questions,
        savedNotes: notes,
        savedTranscript: messages,
        socketURL: socketURLForID(lectureID),
      };
    }
    else if (lectureType === 'saved') {
      //TODO load saved lectures
      const {
        messages,
        highlights,
        questions,
        notes,
      } = await loadLecture(lectureID);
      return {
        lectureType,
        lectureID,
        lectureDisplayName,
        savedHighlights: highlights,
        savedQuestions: questions,
        savedNotes: notes,
        savedTranscript: messages,
      };
    }
    else return lectureType;
  }
);

export type NoteState = readonly [
  props: NoteData,
  range: Highlight,
  attachmentElements: Map<string, HTMLElement>,
];
export type QuestionState = readonly [
  props: QuestionData,
  range: Highlight,
  attachmentElements: Map<string, HTMLElement>,
];

export interface PhraseProps {
  phrase: Phrase_;
  highlights: Highlight[];
  notes: NoteState[];
  questions: QuestionState[];
}
export const Phrase = ({
  phrase,
  highlights,
  notes,
  questions,
}: PhraseProps) => {
  const phraseChildren: React.ReactElement[] = [];
  let spanChildren: (SegmentProps & { key: string })[] = [];
  let spanClasses = new Set<string>();
  let spanNoteState: NoteState | undefined = undefined;
  let spanQuestionStates = new Set<QuestionState>();
  let index = 0;
  let spanStartIndex = 0;
  const endSpan = (
    newSpanClasses: Set<string>,
    newSpanStartIndex: number,
    newSpanNoteState: NoteState | undefined,
    newSpanQuestionStates: Set<QuestionState>,
  ) => {
    const className = [...spanClasses.keys()].join(' ');
    if (newSpanStartIndex > spanStartIndex || spanChildren.length > 0) {
      const noteState = spanNoteState;
      const questionStates = new Set(spanQuestionStates.keys());
      const spanKey = `${phrase.phraseNumber} ${spanStartIndex}-${newSpanStartIndex - 1} ${className}`;
      const withRef = (element: HTMLSpanElement) => {
        if (noteState) {
          const [note, range, attachmentElements] = noteState;
          if (element) {
            attachmentElements.set(spanKey, element);
          }
          else {
            attachmentElements.delete(spanKey);
          }
        }
        for (const questionState of questionStates.keys()) {
          const [question, range, attachmentElements] = questionState;
          if (element) {
            attachmentElements.set(spanKey, element);
          }
          else {
            attachmentElements.delete(spanKey);
          }
        }
      };
      const lastSegment = {...spanChildren[spanChildren.length - 1].segment};
      const savedWhitespace = (
        // don't save whitespace if we're in an unstyled span
        spanClasses.size === 0 ? undefined
        // or we're looking at a span with just a whitespace child we created
        : spanChildren.length === 1 && spanChildren[0].key.startsWith('space before') ? undefined
        : lastSegment.content.match(markdownWhitespaceRegex)?.[0]
      );
      if (savedWhitespace) {
        lastSegment.content = lastSegment.content.trimEnd();
        spanChildren[spanChildren.length - 1].segment = lastSegment;
      }
      phraseChildren.push((
        <span
          className={className}
        key={`${spanKey} ${
          // without this, we were getting key collisions when an overlapping span was created then deleted
          spanChildren.map(ch => ch.key)
        }`}
          ref={withRef}
        >
          {spanChildren.map(ch => (
          <PhraseSegment
            {...ch}
          />
          ))}
        </span>
      ));
      if (savedWhitespace) {
        spanChildren = [{
          key: `space before ${newSpanStartIndex}`,
          phraseNumber: phrase.phraseNumber,
          segment: {
            ...lastSegment,
            startTime: lastSegment.endTime,
            endTime: lastSegment.endTime + 0.5,
            content: savedWhitespace,
          },
        }];
        // set spanClasses to its intersection with newSpanClasses
        spanClasses = new Set([...spanClasses.keys()]
          .filter(c => newSpanClasses.has(c))
        );
        // remove questions and notes that aren't in the intersection
        if (spanNoteState && !spanClasses.has(`noted ${spanNoteState[0].id}`)) {
          spanNoteState = undefined;
        }
        spanQuestionStates = new Set([...spanQuestionStates.keys()]
          .filter(q => spanClasses.has(`questioned ${q[0].id}`))
        );
        // make a span with just the saved whitespace, annotated with just the intersection
        endSpan(
          newSpanClasses,
          newSpanStartIndex,
          newSpanNoteState,
          newSpanQuestionStates,
        );
        // since that call also did the cleanup we were about to do, we're done!
        return;
      }
      else {
        spanChildren = [];
      }
      spanStartIndex = newSpanStartIndex;
    }
    spanClasses = newSpanClasses;
    spanNoteState = newSpanNoteState;
    spanQuestionStates = newSpanQuestionStates;
  };
  for (const seg of phrase.segments) {
    let spanMatch = true;
    let segClasses = new Set<string>();
    if (highlights.some(h => highlightedBy(seg, h))) {
      spanMatch = spanMatch && spanClasses.has('highlighted');
      segClasses.add('highlighted');
    }
    const noteState = notes.find(noteState => highlightedBy(seg, noteState[1]));
    if (noteState !== undefined) {
      const [note, _, __] = noteState;
      const noteClass = `noted ${note.id}`;
      spanMatch = spanMatch && spanClasses.has(noteClass);
      segClasses.add(noteClass);
    }
    const questionStates = new Set(questions.filter(questionState => highlightedBy(seg, questionState[1])));
    for (const questionState of questionStates.keys()) {
      const [question, _, __] = questionState;
      const questionClass = `questioned ${question.id}`;
      spanMatch = spanMatch && spanClasses.has(questionClass);
      segClasses.add(questionClass);
    }
    spanMatch = spanMatch && segClasses.size === spanClasses.size;
    if (!spanMatch) {
      endSpan(
        segClasses,
        index,
        noteState,
        questionStates,
      );
    }
    spanChildren.push({
      segment: seg,
      phraseNumber: phrase.phraseNumber,
      key: `${phrase.phraseNumber} ${seg.startTime} ${seg.endTime} ${index}`,
    });
    index++;
  }
  endSpan(
    new Set(),
    index,
    undefined,
    new Set(),
  );
  return (<p>
    {phraseChildren}
  </p>);
};

interface SegmentProps {
  segment: PhraseSegment_;
  phraseNumber: number;
}
const PhraseSegment = ({
  segment,
  phraseNumber,
}: SegmentProps) => {
  const selectionContext = useContext(SavedSelectionContext);
  let ref: React.RefCallback<HTMLElement> | undefined = undefined;
  if (selectionContext) {
    const checkEndpoint = (endpoint: SelectionEndpoint) => {
      if (endpoint.type === 'existing') return false;
      if (phraseNumber !== (endpoint.actualPhrase ?? selectionContext!.phraseNumber)) return false;
      return (
        endpoint.content === segment.content
        && endpoint.range[0] === segment.startTime
        && endpoint.range[1] === segment.endTime
      );
    }
    const isAnchor = checkEndpoint(selectionContext.anchor);
    const isFocus = checkEndpoint(selectionContext.focus);
    if (isAnchor && isFocus) {
      ref = e => {
        selectionContext.anchorRef!(e);
        selectionContext.focusRef!(e);
      }
    }
    else if (isAnchor) {
      ref = selectionContext.anchorRef;
    }
    else if (isFocus) {
      ref = selectionContext.focusRef;
    }
  }
  return (
    <span
      ref={ref}
      data-phrase-number={phraseNumber}
      data-start-time={segment.startTime}
      data-end-time={segment.endTime}>
      {segment.emphasized ? (
        <span className='emphasized'>{segment.content}</span>
      ) : (
        <>{segment.content}</>
      )}
    </span>
  );
};

interface SetGlobalStyleProps {
}
const SetGlobalStyle = createGlobalStyle<SetGlobalStyleProps>`
body {
  background-color: #222;
  color: #FFF;
}
`;

const NotificationButton = () => {
  const [permissionGranted, setPermissionGranted] = useState(
    !('Notification' in window) ||
    Notification.permission === 'granted' ||
    (() => {
      // Chrome on mobile OSes, and no other browser, "supports" "notifications"
      // without supporting the Notification constructor. This can be feature-detected
      // by trying to construct a notification before getting permission.
      try {
        new Notification("test notification");
      }
      catch (e) {
        if (tryAccess(e, 'name') === 'TypeError') {
          return true;
        }
      }
      return false;
    })()
  );
  const requestPermission = useCallback(() => {
    Notification.requestPermission().then(answer => {
      if (answer === 'granted') {
        setPermissionGranted(true);
      }
    });
  }, [
    setPermissionGranted,
  ]);
  if (permissionGranted) return null;
  return (
    <button onClick={requestPermission}>
      Enable Emphasis Notifications
    </button>
  );
};
const notifyEmphasis = (teacherName?: string) => {
  console.log("notifyEmphasis");
  if (!window.Notification) return;
  console.log("notification constructor present");
  if (Notification.permission !== 'granted') return;
  console.log("notification permission granted");
  new Notification(`${teacherName ?? "Your teacher"} wants your attention!`, {
    tag: "transcribbit emphasis",
    vibrate: [150, 100, 150],
    renotify: true,
  });
  console.log("notification constructed");
}

const markdownNewlineRegex = /  \n$/g;
const markdownWhitespaceRegex = /\s+$/g;

interface ExistingSelectionEndpoint {
  type: 'existing';
  node: Node;
  offset: number;
}
interface NewSelectionEndpoint {
  type: 'new';
  position: 'start' | 'end';
  range: Highlight;
  content?: string;
  actualPhrase?: number;
}
type SelectionEndpoint = ExistingSelectionEndpoint | NewSelectionEndpoint;
interface SavedSelection {
  anchor: SelectionEndpoint;
  focus: SelectionEndpoint;
  phraseNumber: number;
}
interface SavedSelectionContextData extends SavedSelection {
  invalid?: true;
  debugID?: string;
  anchorRef?: React.RefCallback<HTMLElement>;
  focusRef?: React.RefCallback<HTMLElement>;
}
const SavedSelectionContext = createContext<SavedSelectionContextData | undefined>(undefined);

export type PlaybackStatus = 'play' | 'pause' | 'end';
export interface ReplayControlsProps {
  /**
   * When called, should remove all transcript content from the transcript display,
   * and make sure messages with messageNumber starting from 0 can be broadcast.
   */
  clearState: () => void;
  broadcastMessage: (m: Message) => void;
  /**
   * Should contain one message per phrase representing the final state of that phrase.
   */
  savedTranscript: Message[];
  notifyEmphasis: () => void;
  onChangePlayStatus?: (status: PlaybackStatus, time: number, speed: number) => void;
}
export const ReplayControls = ({
  clearState,
  broadcastMessage,
  savedTranscript,
  notifyEmphasis,
  onChangePlayStatus,
}: ReplayControlsProps) => {
  /*
   * goals:
   * 4. pause
   * 5?. playback speed
   */
  const [playbackStatus, setPlaybackStatus] = useState<PlaybackStatus>('end');
  const [playbackStartTime, setPlaybackStartTime] = useState(0);
  interface PlaybackState {
    nextPhrase: number;
    nextSegment: number;
    inEmphasis: boolean;
    startsEmphasis: boolean;
  }
  const advance = useCallback((ps: PlaybackState): PlaybackState | 'end' => {
    let thisPhrase = savedTranscript[ps.nextPhrase];
    if (thisPhrase === undefined) return 'end';
    if (thisPhrase.phrase.segments.length > (ps.nextSegment + 1)) {
      const inEmphasis = thisPhrase.phrase.segments[ps.nextSegment + 1].emphasized;
      return {
        nextPhrase: ps.nextPhrase,
        nextSegment: ps.nextSegment + 1,
        inEmphasis,
        startsEmphasis: inEmphasis && !ps.inEmphasis,
      };
    }
    else {
      thisPhrase = savedTranscript[ps.nextPhrase + 1];
      if (thisPhrase === undefined) return 'end';

      if (thisPhrase.phrase.segments.length === 0) {
        // keep advancing until we get to a phrase with content
        return advance({
          ...ps,
          nextPhrase: ps.nextPhrase + 1,
          nextSegment: 0,
        });
      }

      const inEmphasis = thisPhrase.phrase.segments[0].emphasized;
      return {
        nextPhrase: ps.nextPhrase + 1,
        nextSegment: 0,
        inEmphasis,
        startsEmphasis: inEmphasis && !ps.inEmphasis,
      };
    }
  }, [
    savedTranscript,
  ]);
  const playbackStateRef = useRef<PlaybackState | 'end'>('end');
  const broadcast = useCallback((ps: PlaybackState) => {
    const previousMessageNumber = savedTranscript[ps.nextPhrase - 1]?.msgNumber ?? 0;
    const message = savedTranscript[ps.nextPhrase];
    const segments = message?.phrase.segments.slice(0, ps.nextSegment + 1);
    if (segments === undefined) {
      // error condition; savedTranscript changed since this state was created
      return;
    }
    if (ps.startsEmphasis) {
      notifyEmphasis();
    }
    broadcastMessage({
      // we need a unique message number for this point in the replay
      msgNumber: previousMessageNumber // higher than the last phrase
      + ( ( (message.msgNumber - previousMessageNumber)
            / message.phrase.segments.length)
            // each segment gets a point evenly spaced between last message and this one
          * ps.nextSegment + 1)
      + (ps.nextSegment + 1 === message.phrase.segments.length
        ? 0 // when the message is identical to a real one, make its number the same
        : (1 / 16)), // otherwise, prevent collision with real messages
      phrase: {
        phraseNumber: ps.nextPhrase,
        segments,
      },
    });
  }, [
    savedTranscript,
    broadcastMessage,
    notifyEmphasis,
  ]);
  /**
   * Returns a time relative to playback start.
   */
  const timeForState = useCallback((ps: PlaybackState) => {
    return savedTranscript[ps.nextPhrase]?.phrase.segments[ps.nextSegment]?.startTime ?? 0;
  }, [
    savedTranscript,
  ]);
  const [nextBroadcastTimeout, setNextBroadcastTimeout] = useState<ReturnType<typeof setTimeout>>();
  const onTimeout = useCallback(() => {
    if (playbackStateRef.current === 'end') {
      console.log('Reached end');
      setPauseTime(0);
      setPlaybackStatus('end');
      onChangePlayStatus?.('end', Date.now() - playbackStartTime, 0);
      return;
    }
    broadcast(playbackStateRef.current);
    playbackStateRef.current = advance(playbackStateRef.current);
    if (playbackStateRef.current === 'end') {
      console.log('Reached end');
      setPauseTime(0);
      setPlaybackStatus('end');
      onChangePlayStatus?.('end', Date.now() - playbackStartTime, 0);
      return;
    }
    const delay = timeForState(playbackStateRef.current) + playbackStartTime - Date.now();
    if (delay <= 0) {
      onTimeout();
    }
    else {
      console.log(`Setting timeout, delay = ${delay}`);
      setNextBroadcastTimeout(setTimeout(() => onTimeoutRef.current(), delay));
    }
  }, [
    playbackStateRef,
    broadcast,
    advance,
    timeForState,
    playbackStartTime,
  ]);
  const onTimeoutRef = useRef(onTimeout);
  useEffect(() => {
    onTimeoutRef.current = onTimeout;
  }, [
    onTimeout,
  ]);
  const [pauseTime, setPauseTime] = useState(0);
  const onUnmount = useCallback(() => {
    nextBroadcastTimeout && clearTimeout(nextBroadcastTimeout);
    onChangePlayStatus?.('pause', 0, 0);
  }, [
    nextBroadcastTimeout,
    onChangePlayStatus,
  ]);
  const onUnmountRef = useRef(onUnmount);
  useEffect(() => { onUnmountRef.current = onUnmount; }, [onUnmount]);
  useEffect(() => () => onUnmountRef.current(), []);

  const play = useCallback((pauseTime = 0) => {
    if (playbackStateRef.current === 'end') return;
    // starting conditions:
    // - playbackStateRef points to a valid segment after the current transcript end
    nextBroadcastTimeout && clearTimeout(nextBroadcastTimeout);
    setPlaybackStartTime(Date.now() - pauseTime);
    const firstDelay = timeForState(playbackStateRef.current) - pauseTime;
    console.log(`play(), first delay is ${firstDelay}`);
    setNextBroadcastTimeout(setTimeout(
      () => onTimeoutRef.current(),
      firstDelay,
    ));
    setPlaybackStatus('play');
    onChangePlayStatus?.('play', pauseTime, 1);
  }, [
    nextBroadcastTimeout,
    timeForState,
    playbackStatus,
    onChangePlayStatus,
  ]);
  const jumpToStart = useCallback(() => {
    clearState();
    playbackStateRef.current = advance({
      nextPhrase: 0,
      nextSegment: -1,
      inEmphasis: false,
      startsEmphasis: false,
    });
    setPauseTime(0);
    if (playbackStatus === 'play') {
      nextBroadcastTimeout && clearTimeout(nextBroadcastTimeout);
      play(0);
    }
  }, [
    clearState,
    advance,
    play,
    playbackStatus,
    nextBroadcastTimeout,
  ]);
  const startOrResume = useCallback(() => {
    if (playbackStatus === 'end') jumpToStart();
    play(pauseTime);
  }, [
    play,
    pauseTime,
  ]);
  const stop = useCallback(() => {
    if (playbackStatus === 'end' || playbackStateRef.current === 'end') return; // nothing to do
    nextBroadcastTimeout && clearTimeout(nextBroadcastTimeout);
    savedTranscript
      .slice(playbackStateRef.current.nextPhrase)
      .map(broadcastMessage);
    playbackStateRef.current = 'end';
    setPlaybackStatus('end');
    setPauseTime(0);

    if (!onChangePlayStatus) return;
    const lastSegment = savedTranscript
      .slice()
      .reverse()
      .find(m => m.phrase.segments.length > 0)
      ?.phrase.segments.at(-1);
    onChangePlayStatus('end', lastSegment?.endTime ?? 0, 0);
  }, [
    nextBroadcastTimeout,
    savedTranscript,
    broadcastMessage,
    onChangePlayStatus,
  ]);
  const pause = useCallback(() => {
    if (playbackStatus !== 'play') return; // nothing to do
    nextBroadcastTimeout && clearTimeout(nextBroadcastTimeout);
    const pauseTime = Date.now() - playbackStartTime;
    setPauseTime(pauseTime);
    setPlaybackStatus('pause');
    if (!onChangePlayStatus) return;
    onChangePlayStatus('pause', pauseTime, 0);
  }, [
    playbackStatus,
    nextBroadcastTimeout,
    playbackStartTime,
  ]);
  return (<div className='playbackControls'>
    <button onClick={jumpToStart}>
      ⏮
    </button>
    {playbackStatus === 'play' ? (
      <button onClick={pause}>
        ⏸
      </button>
    ) : (
      <button onClick={startOrResume}>
        ⏵
      </button>
    )}
    <button onClick={stop}>
      ⏭
    </button>
  </div>);
};

const LecturePage = () => {
  const loaderData = useLoaderData() as LoaderData;
  const {
    lectureType,
    lectureID,
    savedHighlights,
    savedNotes,
    savedQuestions,
  } = loaderData;
  const socketURL = lectureType === 'live' ? loaderData.socketURL : undefined;
  useVibrationSubscription(
    notifyEmphasis,
    `${socketURL ?? ""}vibration/`,
    socketURL !== undefined,
  );
  // "report" here means that the highlights in any type "new" endpoints are the start/end times of the old
  // phrase segments, not the new ones
  const [savedSelectionReport, setSavedSelectionReport] = useState<SavedSelection>();
  const receiveMessage = useCallback((m: Message) => {
    const selection = window.getSelection();
    broadcastMessage(m);
    if (
      selection
      && !selection.isCollapsed
      // I've observed cases in the wild where only one of these is null but the selection is not collapsed.
      // However, these cases are perverse as fuck and I don't know how we even *could* handle them well, since
      // there's no way to tell which direction the selection is going or where it ends.
      && selection.anchorNode
      && selection.focusNode
    ) {
      let anchorNode = selection.anchorNode;
      const focusNode = selection.focusNode;

      let anchorStartTime_ = getDataAttributeFromText(anchorNode, 'startTime')!;
      let anchorEndTime_ = getDataAttributeFromText(anchorNode, 'endTime')!;
      let anchorPhrase_ = getDataAttributeFromText(anchorNode, 'phraseNumber')!;

      const focusStartTime_ = getDataAttributeFromText(focusNode, 'startTime')!;
      const focusEndTime_ = getDataAttributeFromText(focusNode, 'endTime')!;
      const focusPhrase_ = getDataAttributeFromText(focusNode, 'phraseNumber')!;
      if ([
        anchorStartTime_,
        anchorEndTime_,
        anchorPhrase_,
        focusStartTime_,
        focusEndTime_,
        focusPhrase_,
      ].some(data => data === undefined)) {
        if (
          focusStartTime_ !== undefined
          && focusEndTime_ !== undefined
          && focusPhrase_ !== undefined
        ) {
          // sometimes the selection seems to get anchored to the transcriptContent div
          // the best we can do is use the focus for both roles
          //TODO is this right? we might be able to do some DOM-crawling...
          anchorStartTime_ = anchorStartTime_ ?? focusStartTime_;
          anchorEndTime_ = anchorEndTime_ ?? focusEndTime_;
          anchorPhrase_ = anchorPhrase_ ?? focusPhrase_;
          anchorNode = focusNode;
        }
        else {
          // I think this only happens if the selection includes text outside the transcript
          // seems okay to break those
          return;
        }
      }
      //TODO handle the bullshit where an endpoint is in an element but selects none of its content
      const anchorStartTime = Number.parseInt(anchorStartTime_);
      const anchorEndTime = Number.parseInt(anchorEndTime_);
      const anchorPhrase = Number.parseInt(anchorPhrase_);
      const focusStartTime = Number.parseInt(focusStartTime_);
      const focusEndTime = Number.parseInt(focusEndTime_);
      const focusPhrase = Number.parseInt(focusPhrase_);

      const anchorFirst =
        (anchorStartTime < focusStartTime) ? true
        : (focusStartTime < anchorStartTime) ? false
        : (anchorNode === focusNode) ? selection.anchorOffset < selection.focusOffset
        // weird case where the nodes have the same start time but are different
        // I think this only happens with punctuation/whitespace?
        // seems okay to just not handle, let me know if anyone complains
        : true;
      const report: SavedSelection = {
        anchor: {
          type: 'existing',
          node: anchorNode,
          offset: selection.anchorOffset,
        },
        focus: {
          type: 'existing',
          node: focusNode,
          offset: selection.focusOffset,
        },
        phraseNumber: m.phrase.phraseNumber,
      }
      if (anchorPhrase === m.phrase.phraseNumber) {
        report.anchor = {
          type: 'new',
          range: [anchorStartTime, anchorEndTime],
          position: anchorFirst ? 'start' : 'end',
        }
      }
      if (focusPhrase === m.phrase.phraseNumber) {
        report.focus = {
          type: 'new',
          range: [focusStartTime, focusEndTime],
          position: anchorFirst ? 'end' : 'start',
        }
      }
      if (report.anchor.type === 'new'
        || report.focus.type === 'new') {
        setSavedSelectionReport(report);
      }
      else {
        setSavedSelectionReport(undefined);
      }
    }
    else {
      // if there's no selection to save
      setSavedSelectionReport(undefined);
    }
  }, [
    setSavedSelectionReport,
  ]);
  const [state, resetState] = useResettableSubscription();
  const savedTranscript = useMemo(() => (
    loaderData.lectureType === 'sample' ? [] : loaderData.savedTranscript
  ), [
    loaderData,
  ]);
  useEffect(() => {
    savedTranscript.map(receiveMessage);
  }, [
    savedTranscript,
    receiveMessage,
  ]);
  const lastSeenMessageNumber = Math.max(
    state?.[state.length - 1]?.msgNumber ?? 0,
    savedTranscript?.[savedTranscript.length - 1]?.msgNumber ?? 0,
  );
  useLectureSubscription(
    receiveMessage,
    lectureID,
    `${socketURL ?? ""}transcript/`,
    lastSeenMessageNumber,
    socketURL !== undefined,
  );
  const [highlights, addHighlight] = useReducer((highlights: Highlight[], highlight: Highlight) => {
    return highlights.concat([highlight]);
  }, savedHighlights);
  const noteContent = useRef(new Map<string, string>(savedNotes.map(n => [n.id, n.content])));
  const questionContent = useRef(new Map<string, string>(savedQuestions.map(q => [q.id, q.questionText])));
  const [notes, updateNotes] = useReducer((
    notes: NoteState[],
    event: {type: 'create', range: Highlight} | {type: 'delete', id: string},
  ) => {
    if (event.type === 'create') {
      const { range } = event;
      const existingNote = notes.find(n => highlightsOverlap(n[1], range));
      if (existingNote) {
        return notes.map(note => (
          note[0].id === existingNote[0].id ? [
            {
              ...note[0],
              refocusCount: note[0].refocusCount === undefined ? 0 : note[0].refocusCount + 1,
            },
            note[1],
            note[2],
          ] as const : note
        ));
      }
      return notes.concat([[
        {
          id: `note-${uuidv4()}`,
          focusOnMount: true,
        },
        range,
        new Map<string, HTMLElement>(),
      ]]);
    }
    else if (event.type === 'delete') {
      return notes.filter(n => n[0].id !== event.id);
    }
    else {
      // event should be never here
      return event;
    }
  }, savedNotes.map((n): NoteState => [
    {
      id: n.id,
      initialText: n.content,
    },
    n.range,
    new Map<string, HTMLElement>(),
  ]));
  const addNote = useCallback((range: Highlight) => updateNotes({
    type: 'create',
    range,
  }), [
    updateNotes,
  ]);
  const deleteNote = useCallback((id: string) => updateNotes({
    type: 'delete',
    id,
  }), [
    updateNotes,
  ]);
  const [questions, updateQuestions] = useReducer((
    questions: QuestionState[],
    event: {type: 'create', range: Highlight} | {type: 'delete', id: string},
  ) => {
    if (event.type === 'create') {
      const { range } = event;
      return questions.concat([[
        {
          id: `question-${uuidv4()}`,
          focusOnMount: true,
        },
        range,
        new Map<string, HTMLElement>(),
      ]]);
    }
    else if (event.type === 'delete') {
      return questions.filter(n => n[0].id !== event.id);
    }
    else {
      // event should be never here
      return event;
    }
  }, savedQuestions.map((q): QuestionState => [
    {
      id: q.id,
      initialText: q.questionText,
    },
    [q.startTime, q.endTime],
    new Map<string, HTMLElement>(),
  ]));
  const addQuestion = useCallback((range: Highlight) => updateQuestions({
    type: 'create',
    range,
  }), [
    updateQuestions,
  ]);
  const deleteQuestion = useCallback((id: string) => updateQuestions({
    type: 'delete',
    id,
  }), [
    updateQuestions,
  ]);
  const [askedQuestions, setAskedQuestions] = useState(savedQuestions);
  const askQuestion = useCallback((q: Question_) => {
    setAskedQuestions(asked => asked.concat([q]));
  }, [
    setAskedQuestions,
  ]);
  useSendQuestions(
    askedQuestions,
    setAskedQuestions,
    loaderData.lectureType === 'live',
    lectureID,
    `${socketURL ?? ""}studentQuestions/`,
    savedQuestions,
  );
  useEffect(() => {
    if (loaderData.lectureType === 'sample') {
      demoStart(
        loaderData.lectureID,
        receiveMessage,
        notifyEmphasis,
      );
      return demoEnd;
    }
  }, [loaderData]);

  const {
    ref,
    height,
  } = useResizeDetector({
    handleWidth: false,
  });
  const {
    knownLectures,
  } = useContext(KnownLecturesContext);
  const lecture = useMemo(() => (
    knownLectures.get(`${lectureType} ${lectureID}`)
  ), [
    knownLectures,
  ]);
  const [inReplay, setInReplay] = useState(false);
  const onChangePlayStatus = useCallback((status: PlaybackStatus, time: number, speed: number) => {
    setInReplay(status !== 'end');
  }, [
    setInReplay,
  ]);
  useSaveLectureOnUnmount(
    lectureID,
    inReplay ? savedTranscript : state,
    highlights,
    notes.map(n => [n[0].id, n[1]]),
    noteContent.current,
    questions.map(q => [q[0].id, q[1]]),
    questionContent.current,
    lecture,
  );
  const doExport = useCallback(() => {
    let content = "";
    const footnotes: string[] = [];
    let wasEmphasized = false;
    let wasHighlighted = false;
    let previousQuestion = -1;
    let previousNote = -1;
    const closeFormatting = (
      isEmphasized: boolean,
      isHighlighted: boolean,
      currentQuestion: number,
      currentNote: number,
    ) => {
      const savedNewline = content.endsWith('  \n');
      if (savedNewline) {
        content = content.replace(markdownNewlineRegex, "");
      }
      const savedWhitespace = content.match(markdownWhitespaceRegex)?.[0];
      if (savedWhitespace) {
        content = content.trimEnd();
      }
      if (previousQuestion !== -1 && currentQuestion !== previousQuestion) {
        //TODO find a way to show the range a question is about
        content += ` [^${footnotes.length + 1}]`;
        footnotes.push(`*${questionContent.current.get(questions[previousQuestion][0].id)}*`); // italicize questions to distinguish from notes
        //TODO if question answers are ever implemented, include the answer somehow
      }
      if (previousNote !== -1 && currentNote !== previousNote) {
        content += '__'; // use underline for notes
        content += ` [^${footnotes.length + 1}]`;
        footnotes.push(noteContent.current.get(notes[previousNote][0].id) ?? "");
      }
      if (wasHighlighted && !isHighlighted) content += '`'; // use code block for highlight
      if (wasEmphasized && !isEmphasized) content += '**'; // use bold for emphasis
      if (savedWhitespace) {
        content += savedWhitespace;
      }
      if (savedNewline) {
        content += "  \n";
      }
    };
    for (const message of state) {
      for (const segment of message.phrase.segments) {
        // if segment is just a newline,
        if (segment.content === '\n') {
          // use a markdown-compliant newline
          content += '  \n';
          // and skip formatting
          continue;
        }
        // skip empty or all-whitespace segments
        if (segment.content.trim() === "") {
          content += segment.content;
          continue;
        }

        const isEmphasized = segment.emphasized;
        const isHighlighted = highlights.some(h => highlightedBy(segment, h));
        const currentQuestion = questions.findIndex(q => highlightedBy(segment, q[1]));
        const currentNote = notes.findIndex(n => highlightedBy(segment, n[1]));

        closeFormatting(
          isEmphasized,
          isHighlighted,
          currentQuestion,
          currentNote,
        );

        // open new formatting
        if (isEmphasized && !wasEmphasized) content += '**'; // use bold for emphasis
        if (isHighlighted && !wasHighlighted) content += '`'; // use code block for highlight
        if (currentNote !== -1 && currentNote !== previousNote) {
          content += '__'; // use underline for notes
        }
        if (currentQuestion !== -1 && currentQuestion !== previousQuestion) {
          //TODO find a way to show the range a question is about
        }

        wasEmphasized = isEmphasized;
        wasHighlighted = isHighlighted;
        previousNote = currentNote;
        previousQuestion = currentQuestion;

        content += segment.content
        // replace newlimes with markdown-compliant ones
          .replaceAll('\n', '  \n');
      }
    }
    closeFormatting(
      false,
      false,
      -1,
      -1,
    );
    if (footnotes.length > 0) {
      content += '\n\n';
      footnotes.forEach((footnote, index) => {
        content += `[^${index + 1}]: ${footnote}  ${'\n'}`;
      });
    }

    loadSavedLectures().then(lectures => {
      const thisLecture = lectures.find(l => l.id === lectureID);
      const lectureName =
        thisLecture ? displayName(thisLecture)
        : "lecture";
      const blob = new Blob([content], {
        type: "text/plain;charset=utf-8",
      });
      FileSaver.saveAs(blob, `${lectureName}.md`);
    });
  }, [
    state,
    highlights,
    questions,
    notes,
    questionContent,
    noteContent,
    lectureID,
  ]);
  const [selectionContextData, setSelectionContextData] = useState<SavedSelectionContextData>();
  useEffect(() => {
    if (savedSelectionReport === undefined) {
      setSelectionContextData(c => {
        if (c) {
        }
        if (c) c.invalid = true;
        return undefined;
      });
      return;
    }
    const updateEndpoint = (endpoint: SelectionEndpoint): SelectionEndpoint => {
      if (endpoint.type === 'existing') return endpoint;
      const constructResult = (seg: PhraseSegment_, actualPhrase?: number): NewSelectionEndpoint => ({
        type: 'new',
        position: endpoint.position,
        range: [seg.startTime, seg.endTime],
        content: seg.content,
        actualPhrase,
      })
      const segments = state[savedSelectionReport.phraseNumber].phrase.segments.slice();
      if (endpoint.position === 'end') {
        // we're looking for the last selected thing, so search from the end of the array
        segments.reverse();
      }
      const found = segments.find(seg => highlightedBy(seg, endpoint.range));
      if (found) {
        return constructResult(found);
      }

      // if we're still here, the endpoint must have been inside a word that got revised out of existence
      // first, let's look for the outermost segment in the phrase that's inside the outer edge of
      // the old segment
      if (endpoint.position === 'start') {
        // find the first segment that ends at/after the old start time
        const found = segments.find(seg => seg.endTime >= endpoint.range[0]);
        if (found) {
          return constructResult(found);
        }
      }
      else {
        // find the last segment that starts at/before the old end time
        // (note: segments is already reversed)
        const found = segments.find(seg => seg.endTime >= endpoint.range[0]);
        if (found) {
          return constructResult(found);
        }
      }

      // if we're *still* here, there's nothing selected in this phrase at all
      // I guess just put the selection at the end of the next latest phrase with content?
      let phraseNumber = savedSelectionReport.phraseNumber;
      while (phraseNumber > 0) {
        const segments = state[phraseNumber].phrase.segments;
        if (segments.length > 0) {
          return constructResult(segments[segments.length - 1], phraseNumber);
        }
        phraseNumber--;
      }

      // if we're *still* here, there is no transcript at all.
      // this is theoretically possible; nothing should be selected.
      throw "no transcript";
    }
    const newContext: SavedSelectionContextData = {
      ...savedSelectionReport,
      debugID: uuidv4(),
    };
    try {
      newContext.anchor = updateEndpoint(savedSelectionReport.anchor);
      newContext.focus = updateEndpoint(savedSelectionReport.focus);
    }
    catch (e) {
      if (e === "no transcript") {
        document.getSelection()?.collapse(null);
        setSelectionContextData(c => {
          if (c) c.invalid = true;
          return undefined;
        });
        return;
      }
      else {
        throw e;
      }
    }
    let anchorNode: Node | undefined = newContext.anchor.type === 'existing'
      ? newContext.anchor.node
      : undefined;
    let anchorOffset = newContext.anchor.type === 'existing'
      ? newContext.anchor.offset
      : (newContext.anchor.position === 'start' ? 0 : newContext.anchor.content?.length ?? 0);
    let focusNode: Node | undefined = newContext.focus.type === 'existing'
      ? newContext.focus.node
      : undefined;
    let focusOffset = newContext.focus.type === 'existing'
      ? newContext.focus.offset
      : (newContext.focus.position === 'start' ? 0 : newContext.focus.content?.length ?? 0);
    const tryCompletingSelection = () => {
      if (anchorNode && focusNode) {
        document.getSelection()?.setBaseAndExtent(
          anchorNode,
          anchorOffset,
          focusNode,
          focusOffset,
        );
      }
    }
    if (!anchorNode) {
      newContext.anchorRef = element => {
        console.log(`In anchorRef; debugID = ${newContext.debugID}, invalid = ${newContext.invalid}`);
        if (newContext.invalid) return;
        if (element === null) return;
        let node: Node = element;
        // it probably gave us a span with a text child; drill down to that
        while (node.nodeName !== '#text') {
          if (node.firstChild) {
            node = node.firstChild;
          }
          else {
            break;
          }
        }
        anchorNode = node;
        tryCompletingSelection();
      }
    }
    if (!focusNode) {
      newContext.focusRef = element => {
        console.log(`In focusRef; debugID = ${newContext.debugID}, invalid = ${newContext.invalid}`);
        if (newContext.invalid) return;
        if (element === null) return;
        let node: Node = element;
        // it probably gave us a span with a text child; drill down to that
        while (node.nodeName !== '#text') {
          if (node.firstChild) {
            node = node.firstChild;
          }
          else {
            break;
          }
        }
        focusNode = node;
        tryCompletingSelection();
      }
    }
    setSelectionContextData(existingContext => {
      if (existingContext === undefined) {
        return newContext;
      }
      const endpointUnchanged = (oldEndpoint: SelectionEndpoint, newEndpoint: SelectionEndpoint) => {
        if (oldEndpoint.type === 'existing' && newEndpoint.type === 'existing') {
          return oldEndpoint.node === newEndpoint.node && oldEndpoint.type === newEndpoint.type;
        }
        if (oldEndpoint.type === 'new' && newEndpoint.type === 'new') {
          return (
            oldEndpoint.range[0] === newEndpoint.range[0]
            && oldEndpoint.range[1] === newEndpoint.range[1]
            && oldEndpoint.content === newEndpoint.content
            && oldEndpoint.position === newEndpoint.position
          );
        }
        return false;
      };
      if (endpointUnchanged(existingContext.anchor, newContext.anchor)
        && endpointUnchanged(existingContext.focus, newContext.focus)) {
        return existingContext;
      }
      else {
        existingContext.invalid = true;
        return newContext;
      }
    });
  }, [
    savedSelectionReport,
    state,
    setSelectionContextData,
  ]);
  return (<>
    <SetGlobalStyle />
    {lecture ? (
      <Helmet>
        <title>{displayName(lecture)} | Transcribbit Student</title>
      </Helmet>
    ) : (<></>)}
    <nav className='topBar'>
      <span className='backLink'>
        <span className='backArrow'>
          <Link to='/app/'>
              {'\u2190'}
          </Link>
        </span>
        <Link to='/app/'>
          Back to Lecture List
        </Link>
      </span>
      {lecture ? (
        <h1>{displayName(lecture)}</h1>
      ) : (<></>)}
      <div>
        {lectureType === 'saved' ? (<>
          <ReplayControls
            clearState={resetState}
            broadcastMessage={broadcastMessage}
            savedTranscript={savedTranscript}
            notifyEmphasis={notifyEmphasis}
            onChangePlayStatus={onChangePlayStatus}
          />
        </>) : (<></>)}
        <NotificationButton />
        <button className='exportButton' onClick={doExport}>
          Export as Markdown
        </button>
      </div>
    </nav>
    <Sidebar>
      {notes.map(([
        note,
        range,
        attachmentElementsSet,
      ]) => {
        const attachmentElements = [...attachmentElementsSet.values()];
        return (<Note
          {...note}
          startTime={range[0]}
          attachmentElements={attachmentElements}
          deleteNote={deleteNote}
          key={note.id}
          noteContent={noteContent.current}
        />);
      })}
      {questions.map(([
        question,
        range,
        attachmentElementsSet,
      ]) => {
        const attachmentElements = [...attachmentElementsSet.values()];
        return (<Question
          {...question}
          startTime={range[0]}
          endTime={range[1]}
          attachmentElements={attachmentElements}
          deleteQuestion={deleteQuestion}
          key={question.id}
          questionContent={questionContent.current}
          askQuestion={askQuestion}
        />);
      })}
      <SelectionActionMenu
        addHighlight={addHighlight}
        addNote={addNote}
        addQuestion={addQuestion}
      />
    </Sidebar>
    <div
      className='transcriptContent'
      ref={ref}
    >
      <SavedSelectionContext.Provider value={
        /* if there's no saved selection, the context object is due for invalidation */
        savedSelectionReport &&
        selectionContextData
      }>
      {state.map(msg => (
        <Phrase
          phrase={msg.phrase}
          highlights={highlights}
          notes={notes}
          questions={questions}
          key={`${msg.phrase.phraseNumber} ${msg.msgNumber}`}
        />
      ))}
      </SavedSelectionContext.Provider>
    </div>
    <StickToBottom
      watchedValues={[height]}
      threshold={15}
    />
  </>);
};
export default LecturePage;
