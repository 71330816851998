import React, {
  useState,
  useReducer,
  useEffect,
  useContext,
  createContext,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  Link,
  useLoaderData,
  useNavigate,
  useLinkClickHandler,
} from 'react-router-dom';
import {
  Map,
} from 'immutable';
import {
  FileUploader,
} from 'react-drag-drop-files';

import {
  Lecture,
  LiveLecture,
  SavedLecture,
  fetchLectures,
  sampleLectures,
} from "../fetchLectures";
import {
  indexLecture,
  loadSavedLectures,
  exportLecture,
  importLecture,
} from "../db";
import {
  LoginTokenContext,
} from "./LoginPage";

export type KnownLectureString = `${'live' | 'saved' | 'sample'} ${string}`;
export interface KnownLecturesContextData {
  knownLectures: Map<KnownLectureString, Lecture>;
  addKnownLecture(lecture: Lecture): void;
  checkForLecture(
    type: 'live' | 'saved' | 'sample',
    id: string,
  ): Promise<Lecture | undefined>;
}
export const lectureType = (lecture: Lecture) => {
  if (lecture.id.startsWith('SAMPLE')) return 'sample';
  if (lecture.saved) return 'saved';
  return 'live';
}

const knownLecturesContextDefault: KnownLecturesContextData = {
  knownLectures: Map(sampleLectures.map(l => [`${lectureType(l)} ${l.id}`, l])),
  addKnownLecture: () => {},
  checkForLecture: async (...args) => {
    await new Promise(resolve => setTimeout(resolve, 100));
    return await knownLecturesContextValue.value.checkForLecture(...args);
  },
};
export const KnownLecturesContext = createContext<KnownLecturesContextData>({
  knownLectures: Map(),
  addKnownLecture: () => {},
  checkForLecture: async () => undefined,
});
/**
 * For use within loaders, because apparently the only way to get state into a loader
 * is a fucking global variable!
 * Made into a wrapper object so the default checkForLecture can see it update.
 */
export const knownLecturesContextValue: { value: KnownLecturesContextData } = { value: knownLecturesContextDefault };

export const KnownLecturesProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [knownLectures, setKnownLectures] = useState<KnownLecturesContextData['knownLectures']>(
    Map(sampleLectures.map(lecture => [`${lectureType(lecture)} ${lecture.id}`, lecture])
  ));
  const addKnownLecture = useCallback((
    lecture: Lecture,
  ) => {
    setKnownLectures(lectures => lectures.set(
      `${lectureType(lecture)} ${lecture.id}`,
      lecture,
    ));
  }, [
    setKnownLectures,
  ]);
  const {
    loginToken,
  } = useContext(LoginTokenContext);
  const checkForLecture = useCallback((
    type: 'live' | 'saved' | 'sample',
    id: string,
  ) => {
    if (loginToken && loginToken !== 'expired') {
      const result = new Promise<Lecture | undefined>((resolve, reject) => {
        fetchLectures(loginToken).then(fetched => {
          setKnownLectures(known => {
            const lectures = known.merge(fetched.map<[
              KnownLectureString,
              Lecture
          ]>(l => [
              `${lectureType(l)} ${l.id}`,
              l,
            ]));
            resolve(lectures.get(`${type} ${id}`));
            return lectures;
          });
        }).catch(() => {
          resolve(undefined);
        });
      });
      return result;
    }
    return (async () => undefined)();
  }, [
    loginToken,
    setKnownLectures,
  ]);
  const contextValue = useMemo(() => ({
    knownLectures,
    addKnownLecture,
    checkForLecture,
  }), [
    knownLectures,
    addKnownLecture,
    checkForLecture,
  ]);
  useEffect(() => {
    knownLecturesContextValue.value = contextValue;
  }, [
    contextValue,
  ]);
  return (
    <KnownLecturesContext.Provider
      value={contextValue}
      children={children}
    />
  );
};

export interface LoaderData {
  lectures: Lecture[],
}
export const loader = async (): Promise<LoaderData> => {
  console.log("In lecture list loader");
  const lectures = await loadSavedLectures();
  console.dir(lectures);
  return {
    lectures,
  };
};

export const displayName = (lecture: Lecture) => {
  if (lecture.id.startsWith('SAMPLE')) {
    return `Sample - ${lecture.className}`;
  }
  if (lecture.saved && lecture.customName !== undefined) {
    return lecture.customName;
  }
  if (lecture.saved && lecture.id.startsWith('DEMO_LECTURE')) {
    if (lecture.startDate !== undefined) {
      return `Saved Lecture - ${lecture.startDate.toLocaleDateString()}`;
    }
    return "Saved Lecture";
  }
  const prefix = lecture.saved
    ? lecture.startDate === undefined
      ? "Saved - "
      : `${lecture.startDate.toLocaleDateString()} - `
    : "";
  if (lecture.teacherName !== undefined) {
    return `${prefix}${lecture.className} (${lecture.teacherName})`;
  }
  return prefix + lecture.className;
}

interface LectureButtonProps {
  lecture: Lecture;
}
const LectureButton = ({
  lecture,
}: LectureButtonProps) => {
  return (
    <Link to={`lecture/${lectureType(lecture)}/${lecture.id}`}>
      {displayName(lecture)}
    </Link>
  );
};
interface DownloadButtonProps {
  lecture: SavedLecture;
}
const DownloadButton = ({
  lecture,
}: DownloadButtonProps) => {
  const onClick = useCallback(() => exportLecture(lecture), [lecture]);
  return (
    <button className='downloadLecture' onClick={onClick}>
      ⭳
    </button>
  );
}
interface RenameButtonProps {
  lecture: SavedLecture;
}
const RenameButton = ({
  lecture,
}: DownloadButtonProps) => {
  const onClick = useCallback(() => {
    const customName = prompt("Enter a new name for this lecture");
    if (customName === null) return;
    indexLecture(
      lecture.id,
      lecture.className,
      lecture.teacherName,
      lecture.startDate,
      customName,
    );
  }, [lecture]);
  return (
    <button className='renameLecture' onClick={onClick}>
      ✎
    </button>
  );
}
const LectureListPage = () => {
  const {
    lectures: dbSavedLectures,
  } = useLoaderData() as LoaderData;
  const [uploadedLectures, setUploadedLectures] = useState<SavedLecture[]>([]);
  const savedLectures = useMemo(() => (
    dbSavedLectures
      .filter(l => !uploadedLectures.some(l2 => l2.id === l.id))
      .concat(uploadedLectures)
  ), [dbSavedLectures, uploadedLectures]);
  const [liveLectures, setLiveLectures] = useState<Lecture[]>([]);
  const [fetchFailed, setFetchFailed] = useState(false);
  const {
    loginToken,
  } = useContext(LoginTokenContext);
  useEffect(() => {
    let continueFetching = true;
    let fetchTimeout: ReturnType<typeof setTimeout>;
    const doFetch = async () => {
      if (!continueFetching) return;
      try {
        const lectures = await fetchLectures(loginToken!, false);
        setLiveLectures(lectures);
        setFetchFailed(false);
        if (!continueFetching) return;
        fetchTimeout = setTimeout(doFetch, 30 * 1000);
      }
      catch {
        setFetchFailed(true);
        if (!continueFetching) return;
        fetchTimeout = setTimeout(doFetch, 5 * 1000);
      }
    };
    if (loginToken && loginToken !== 'expired') {
      doFetch();
      return () => {
        continueFetching = false;
        clearTimeout(fetchTimeout);
      }
    }
    else {
      setLiveLectures(sampleLectures);
    }
  }, [
    loginToken,
  ]);
  const lectures = liveLectures.concat(savedLectures);
  const {
    addKnownLecture,
  } = useContext(KnownLecturesContext);
  useEffect(() => {
    liveLectures.map(addKnownLecture);
  }, [
    liveLectures,
    addKnownLecture,
  ]);
  useEffect(() => {
    savedLectures.map(addKnownLecture);
  }, [
    savedLectures,
    addKnownLecture,
  ]);
  // toggle between two different but falsy values to tell the file uploader to reset
  const [clearUploadValue, setClearUploadValue] = useState(0);
  const clearUpload = useCallback(() => {
    setClearUploadValue(v => v + 1);
  }, [
    setClearUploadValue,
  ]);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onTypeError = useCallback(() => {
    setErrorMessage(" Only .ribbit files can be imported");
    clearUpload();
  }, [
    clearUpload,
    setErrorMessage,
  ]);
  const uploadLecture = useCallback(async (f: File) => {
    try {
      const l = await importLecture(f);
      setErrorMessage(undefined);
      setUploadedLectures(lectures => {
        const index = lectures.findIndex(l2 => l2.id === l.id);
        if (index === -1) {
          return lectures.concat(l);
        }
        else {
          return lectures
            .slice(0, index)
            .concat(
              l,
              lectures.slice(index + 1)
            );
        }
      });
    }
    catch (e) {
      if (typeof e === 'string') {
        setErrorMessage(' ' + e);
      }
      else {
        console.error(e);
        if (typeof e === 'object' && e instanceof Error) {
          setErrorMessage(' ' + e.message);
        }
        else {
          setErrorMessage(' ' + e);
        }
      }
      clearUpload();
    }
  }, [
    setUploadedLectures,
  ]);
  return (<>
    {loginToken === undefined || loginToken === 'expired' ? (<>
      <br />
      <Link to='/login/'>
        {"Click here to log in"}
      </Link>
      {" or try the app with one of the sample lectures below:"}
    </>) : fetchFailed ? (<>
      <br />
      {"Failed to fetch lectures. "}
      <Link to='/login/'>
        {"Click here to log in,"}
      </Link>
      {" or wait while we try again."}
    </>) : liveLectures.length === 0 ? (<>
      <span className='humor'>{"No frogs here, only crickets..."}</span>
      <br />
      {"(None of your classes have ongoing lectures.)"}
    </>) : (<>
      <br />
      {"Ongoing Lectures:"}
    </>)}
    <ul>
      {liveLectures.map(lecture => (
        <li 
          key={`${lecture.id} ${
            lecture.id.startsWith('SAMPLE_') ? 'sample'
            : lecture.saved ? 'saved'
            : 'live'
          }`}
        >
          <LectureButton lecture={lecture} />
        </li>
      ))}
    </ul>
    {savedLectures.length > 0 ? (<>
      {"Saved Lectures:"}
      <ul>
        {savedLectures.map(lecture => (
          <li 
            key={`${lecture.id} ${
              lecture.id.startsWith('SAMPLE_') ? 'sample'
              : lecture.saved ? 'saved'
              : 'live'
            }`}
          >
            <LectureButton lecture={lecture} />
            {lecture.saved ? (<>
              <RenameButton lecture={lecture} />
              <DownloadButton lecture={lecture} />
            </>) : <></>}
          </li>
        ))}
      </ul>
    </>) : loginToken !== undefined && loginToken !== 'expired' ? (<>
      {"Once you join a lecture, transcripts, questions, and notes will be saved here to review at your convenience."}
    </>) : (<>
    </>)}
    <FileUploader key={clearUploadValue}
      types={useMemo(() => ['ribbit'], [])}
      handleChange={uploadLecture}
      label={errorMessage ?? "Upload or drag a .ribbit file here to import a lecture backup"}
      onTypeError={onTypeError} 
    >
    </FileUploader>
  </>);
};
export default LectureListPage;
