import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import {
  Helmet,
} from 'react-helmet';

import ErrorPage from "./ErrorPage";
import LectureListPage, {
  loader as lectureListLoader,
  KnownLecturesProvider,
} from "./routes/LectureListPage";
import LecturePage, {
  loader as lectureLoader,
} from "./routes/LecturePage";
import LoginPage, {
  LoginProvider,
  MaintainLoginWrapper,
} from "./routes/LoginPage";
import {
  loader as importLectureLoader,
} from "./routes/ImportLecture";
import MakeLectureShareLink, {
} from "./routes/MakeLectureShareLink";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to={'/app/'} />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login/',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/app',
    element: <MaintainLoginWrapper />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/app/',
        loader: lectureListLoader,
        element: <LectureListPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/app/import/:encodedURL/',
        loader: importLectureLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: '/app/lecture/:lectureType/:lectureID/',
        loader: lectureLoader,
        element: <LecturePage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/app/export/',
        element: <MakeLectureShareLink />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

function App() {
  return (
    <LoginProvider>
      <KnownLecturesProvider>
        <Helmet>
          <title>Transcribbit Student</title>
        </Helmet>
        <RouterProvider router={router} />
      </KnownLecturesProvider>
    </LoginProvider>
  );
}

export default App;
